import { api } from '../api';

type UpdateBoardType = {
  id: number;
  name: string;
  viewOrder?: string;
};

type CreateBoardType = {
  name: string;
  viewOrder: string;
  profileId: number;
};

import type { BoardsByRivalIdReturnType } from 'api/endpoints/boards/boards.types';

export type GetBoardsByRivalIdQuery = {
  path: {
    rivalId: number;
  };
  query?: {
    visibilityGroupId: number;
  };
};

const injectedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBoardsByRivalId: build.query<
      BoardsByRivalIdReturnType,
      GetBoardsByRivalIdQuery
    >({
      query: ({ path: { rivalId }, query }) => ({
        url: `/rivals/${rivalId}/boards.json`,
        params: query,
      }),
      transformResponse: (returnValue: BoardsByRivalIdReturnType) => {
        return returnValue;
      },
    }),
    updateBoard: build.mutation<any, UpdateBoardType>({
      query: (board) => {
        const { id, ...body } = board;
        return {
          url: `/boards/${id}.json`,
          method: 'PUT',
          body,
          headers: {
            'Content-Type': 'application/json',
          },
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    createBoard: build.mutation<any, Partial<CreateBoardType>>({
      query: (body) => {
        return {
          url: `/boards.json`,
          method: 'POST',
          body,
          headers: {
            'Content-Type': 'application/json',
          },
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    deleteBoard: build.mutation<void, number>({
      query: (boardId) => ({
        url: `/boards/${boardId}.json`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      transformResponse: (response: any) => {
        return response;
      },
    }),
  }),
});

export const enhancedApi = injectedApi.enhanceEndpoints({
  addTagTypes: ['Boards'],
});

export const {
  useGetBoardsByRivalIdQuery,
  useCreateBoardMutation,
  useDeleteBoardMutation,
  useUpdateBoardMutation,
} = enhancedApi;
