import 'wicg-inert';

import { NotifierProvider } from '@kluein/klue-ui';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { AppContextProvider } from 'contexts';
import ErrorBoundary from 'lib/ErrorBoundary';

import App from './App';
import i18nInit from './i18n';
import store from './store';

import './mock-server';

/* Global styles */
import '@kluein/design-tokens/dist/index.css';
import '@kluein/content-editor/styles/klue-content-styles.css';

import 'components/common/klueCard/card-static-html-content.css';
import 'components/common/klueCard/card-static-html-dynamic-blocks.css';
import 'components/common/klueCard/card-static-html-overrides.css';

i18nInit();

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

root.render(
  <React.Suspense fallback={<div />}>
    <ErrorBoundary fullscreen={true}>
      <Provider store={store}>
        <BrowserRouter>
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <AppContextProvider>
              <NotifierProvider>
                <App />
              </NotifierProvider>
            </AppContextProvider>
          </QueryParamProvider>
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>
  </React.Suspense>,
);
