import { WIN_LOSS_PUBLIC_URL_BASE } from 'modules/win-loss/constants';

/**
 * This is the public routes list.
 * In case you need to create a public route, put it
 * inside this array and the `authInterceptor` will
 * be avoided.
 *
 * @see authInterceptor
 */
export const PUBLIC_ROUTES = [WIN_LOSS_PUBLIC_URL_BASE];
