import { KlueNodeViewWrapper } from '@kluein/content-editor';
import {
  Box,
  Button,
  tokens,
  PhosphorIcon,
  useResponsive,
} from '@kluein/klue-ui';

import DynamicBlock from 'components/common/klueCard/blocks/dynamicBlock/DynamicBlock';
import TEST_IDS from 'test-ids';

import SelectFormulaType from './SelectFormulaType';
import SelectRival from './SelectRival';

import { useDynamicBlock } from '../hooks/useDynamicBlock';

import type { KlueNodeViewProps } from '@kluein/content-editor';
import type { Formulas } from 'klue-html/formulas';

const DynamicBlockView = (props: KlueNodeViewProps) => {
  const {
    t,
    expanded,
    setExpanded,
    formulaKey,
    formulaObject,
    isSalesforce,
    hasMultipleCompanies,
    companies,
    mappedCompanies,
    isConfigured,
    blockValid,
    cardId,
    type,
    sfdcQueryType,
    profileId,
    originalFormula,
    handleSelectFormulaType,
    handleSelectRival,
    deleteNode,
  } = useDynamicBlock(props);

  const { maxWidth } = useResponsive();
  const selectedRivalId = mappedCompanies[0] ?? props.node.attrs.rivalId ?? '';

  return (
    <KlueNodeViewWrapper
      data-block-type={props.node.type.name}
      data-formula-key={formulaKey}
      data-companies={JSON.stringify(companies)}
      data-card-id={cardId}
      data-formula-type={formulaKey}
      data-type={type}
      data-sfdc-query-type={sfdcQueryType}
      data-profile-id={profileId}
      data-original-formula={originalFormula}
      data-block-valid={blockValid}
      draggable
      data-drag-handle
      contentEditable={false}
    >
      <Box
        background={
          blockValid
            ? tokens.color.neutral[300]
            : tokens.color.status.warning[100]
        }
        flex={false}
        round="xxsmall"
        contentEditable={false}
        border={{
          side: 'all',
          size: 'small',
          color: blockValid
            ? tokens.color.neutral[500]
            : tokens.color.status.warning[600],
          style: 'dashed',
        }}
      >
        <Box
          direction="row"
          align="center"
          justify="between"
          contentEditable={false}
          pad={'small'}
          border={{
            side: 'bottom',
            size: 'xsmall',
            color: blockValid
              ? tokens.color.neutral[500]
              : tokens.color.status.warning[300],
          }}
        >
          <SelectFormulaType
            currentFormulaType={formulaObject?.label || ''}
            onSelect={(value) => handleSelectFormulaType(value)}
          />
          <Box direction="row" align="center" gap="medium">
            <Button
              icon={<PhosphorIcon.Trash size={tokens.iconSize.small} />}
              variant="plain"
              title={t('Card:editor.extensions.dynamicBlock.removeButtonTitle')}
              size="small"
              onClick={deleteNode}
            />
            <Button
              icon={
                expanded ? (
                  <PhosphorIcon.CaretUp size={tokens.iconSize.small} />
                ) : (
                  <PhosphorIcon.CaretDown size={tokens.iconSize.small} />
                )
              }
              variant="plain"
              disabled={!formulaKey}
              title={t('Card:editor.extensions.dynamicBlock.collapseButton')}
              size="small"
              onClick={() => setExpanded((prev) => !prev)}
            />
          </Box>
        </Box>
        {expanded && (
          <>
            {!isSalesforce && (
              <Box
                pad="medium"
                direction={maxWidth.small ? 'column' : 'row'}
                gap="small"
                flex={{ grow: 1 }}
                background={
                  blockValid
                    ? tokens.color.neutral[200]
                    : tokens.color.status.warning[100]
                }
                data-test-id={
                  TEST_IDS.editor.blocks.dynamicBlockViewRivalSelect
                }
                border={{
                  side: 'bottom',
                  size: 'small',
                  color: tokens.color.neutral[500],
                }}
              >
                <SelectRival
                  onSelect={(rival) => handleSelectRival(rival, 0)}
                  rivalId={selectedRivalId}
                  prefix={
                    !hasMultipleCompanies
                      ? t(
                          'Card:editor.extensions.dynamicBlock.selectRival.label',
                        )
                      : 'Company A'
                  }
                  selectRivalLabel={t(
                    'Card:editor.extensions.dynamicBlock.selectRival.label',
                  )}
                />
                {hasMultipleCompanies && (
                  <SelectRival
                    onSelect={(rival) => handleSelectRival(rival, 1)}
                    rivalId={companies[1] ?? 0}
                    prefix="Company B"
                    selectRivalLabel={t(
                      'Card:editor.extensions.dynamicBlock.selectRival.placeholder',
                    )}
                  />
                )}
              </Box>
            )}
            {isConfigured && (
              <Box
                margin={{ top: 'medium' }}
                data-test-id={TEST_IDS.editor.blocks.dynamicBlockViewFormula}
              >
                <DynamicBlock
                  editorView
                  formula={{
                    formulaType: formulaKey as Formulas,
                    companies,
                    cardId: cardId ?? undefined,
                    extra: {
                      profileId: profileId ?? 0,
                    },
                  }}
                />
              </Box>
            )}
          </>
        )}
      </Box>
    </KlueNodeViewWrapper>
  );
};

export default DynamicBlockView;
