/* eslint-disable max-lines */
import {
  enableLinkClickTracking,
  LinkClickTrackingPlugin,
} from '@snowplow/browser-plugin-link-click-tracking';
import {
  addGlobalContexts,
  clearGlobalContexts,
  enableActivityTracking,
  newTracker,
  setUserId,
  type TrackerConfiguration,
  trackPageView,
  trackSelfDescribingEvent,
  trackStructEvent,
} from '@snowplow/browser-tracker';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import {
  createContext,
  type ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

import { Sort } from 'api/endpoints/intel/intel.enums';
import { useAuth } from 'contexts/auth';
import { checkCuratorAccess, checkGAEnabled } from 'contexts/auth/auth.utils';
import { useFiltersFromURL } from 'hooks/alerts/use-filters-from-url';
import {
  GA_KLUE_TRACKING_NAME,
  GA_INSTANCE_TRACKING_NAME,
} from 'lib/constants';
import { isInTriageMode } from 'pages/alerts/utils';

import {
  csvToArray,
  getCampaignContentFromUrl,
  debugSnowplow,
  SNOWPLOW_SCHEMAS,
} from './analytics.utils';
import {
  type PageTrackerType,
  type StateType,
  type TrackerType,
  type GoogleAnalyticsConfig,
  type SnowplowAnalyticsConfig,
  type AnalyticsParams,
  type EventLoggerType,
  type AnalyticsContextType,
  type SnowplowAnalyticsEvent,
  AnalyticsAction,
} from './AnalyticsProvider.types';

import type { AnalyticsActionType, AnalyticsEvent } from '.';
import type { ResearchModuleActionType } from 'hooks/research/use-research-module-action-log/useResearchModuleActionLog';
import type { Integration } from 'store/api/integrations/integrations.api';

const DEBUG_ANALYTICS = import.meta.env.VITE_DEBUG_ANALYTICS === 'true';

const IGNORED_SNOWPLOW_PATHS_REGEX = /^\/(profile|card\/embed)\/\d+($|\/|\?)/;
// Paths are ignored because they use a new approach for logging page views

export const defaultInstanceTracker = 'sp-0';
export const legacyInstanceTracker = 'sp-legacy';

const TrackingContext = createContext<AnalyticsContextType>({
  logEvent: () => null,
  addTracker: () => null,
  removeTracker: () => null,
  analytics: {
    providers: {},
  },
});

const cleanGoogleAnalyticsUrlParams = (oldUrl: string) => {
  // Clean up any tracking params in the url
  // https://gist.github.com/paulirish/626834
  return oldUrl.replace(/\?([^#]*)/, (location: string, search: string) => {
    search = search
      .split('&')
      .reduce((acc: any, v: any) => {
        if (!/^utm_/.test(v)) {
          acc.push(v);
        }

        return acc;
      }, [])
      .join('&');

    return search ? '?' + search : '';
  });
};

const getUserDomain = (email?: string): string => {
  const userDomainIndex = email?.lastIndexOf('@') ?? -1;
  return email && userDomainIndex >= 0
    ? email?.substring(userDomainIndex + 1)
    : '';
};

function AnalyticsProvider({ children }: { children: ReactElement }) {
  const { user, company } = useAuth();
  const isCurator = checkCuratorAccess(user);
  const isGAEnabled = checkGAEnabled(company);
  const location = useLocation();
  const { filtersFromURL } = useFiltersFromURL();
  const analyticsParams = useMemo<AnalyticsParams | undefined>(() => {
    const userId = user?.id.toString();
    const companyId = company?.id.toString();
    const companyName = company?.name;
    if (userId && companyId && companyName) {
      const { analytics: instanceData = {} } = company?.companyData;

      return {
        companyId,
        companyName,
        debug: DEBUG_ANALYTICS,
        providers: cloneDeep(instanceData),
        userId,
        emailDomain: getUserDomain(user?.email),
        isCurator,
        isV2: true,
      };
    }
  }, [
    company?.companyData,
    company?.id,
    company?.name,
    isCurator,
    user?.email,
    user?.id,
  ]);
  const [analytics, setAnalytics] = useState<StateType>({
    providers: {
      ga: {
        isInitialized: false,
        accountNames: [],
      },
      sp: {
        isInitialized: false,
        trackers: [],
      },
    },
  });

  useEffect(() => {
    if (!analyticsParams) return;

    const {
      companyId,
      companyName,
      debug,
      emailDomain,
      providers: { ga, sp },
      isCurator,
      isV2,
      userId,
    } = analyticsParams;

    const initGA = (instanceConfig?: GoogleAnalyticsConfig) => {
      if (!isGAEnabled) return;

      const accounts = [];
      const accountNames: string[] = [];
      const customDimensions = {
        dimension1: companyId,
        dimension2: companyName,
        dimension3: emailDomain,
        dimension4: isCurator.toString(),
        // dimension5 (SKIP) was used by fullstory in the past
        dimension6: userId, // redundant, but required by Dell for analytics reports
        dimension7: isV2, // we should always send true from v2 application
      };
      const GOOGLE_ANALYTICS_ID = import.meta.env.VITE_GOOGLE_ANALYTICS_ID;

      if (GOOGLE_ANALYTICS_ID) {
        accounts.push({
          trackingId: GOOGLE_ANALYTICS_ID,
          gaOptions: {
            name: GA_KLUE_TRACKING_NAME,
            userId: userId,
          },
          debug,
          titleCase: false,
        });
        accountNames.push(GA_KLUE_TRACKING_NAME);
      }

      if (instanceConfig?.trackingId) {
        accounts.push({
          trackingId: instanceConfig.trackingId,
          gaOptions: {
            name: GA_INSTANCE_TRACKING_NAME,
            userId,
          },
          debug,
          titleCase: false,
        });
        accountNames.push(GA_INSTANCE_TRACKING_NAME);
      }

      if (accounts.length) {
        // initialize GA
        ReactGA.initialize(accounts, {
          alwaysSendToDefaultTracker: false,
          testMode: !!import.meta.env.VITEST_WORKER_ID,
        });

        accounts.forEach(({ trackingId }) =>
          ReactGA.ga('create', trackingId, 'auto', {
            cookieFlags: 'SameSite=None; Secure',
          }),
        );
        ReactGA.set(customDimensions, accountNames);
        setAnalytics(({ providers = {} }) => ({
          providers: {
            ...providers,
            ga: {
              isInitialized: true,
              accountNames: [
                ...(providers.ga?.accountNames || []),
                ...accountNames,
              ],
            },
          },
        }));
      }
    };
    const initSP = (instanceConfig?: SnowplowAnalyticsConfig) => {
      const accounts = [];
      const SNOWPLOW_ANALYTICS_APP_ID = import.meta.env
        .VITE_SNOWPLOW_ANALYTICS_APP_ID;
      const SNOWPLOW_ANALYTICS_COLLECTOR_URL = import.meta.env
        .VITE_SNOWPLOW_ANALYTICS_COLLECTOR_URL;
      const SNOWPLOW_ANALYTICS_POST_PATH = import.meta.env
        .VITE_SNOWPLOW_ANALYTICS_POST_PATH;
      if (
        SNOWPLOW_ANALYTICS_APP_ID &&
        SNOWPLOW_ANALYTICS_COLLECTOR_URL &&
        SNOWPLOW_ANALYTICS_POST_PATH
      ) {
        accounts.push(
          {
            trackerName: defaultInstanceTracker,
            appId: SNOWPLOW_ANALYTICS_APP_ID,
            collectorUrl: SNOWPLOW_ANALYTICS_COLLECTOR_URL,
            postPath: SNOWPLOW_ANALYTICS_POST_PATH,
          },
          {
            trackerName: legacyInstanceTracker,
            appId: SNOWPLOW_ANALYTICS_APP_ID,
            collectorUrl: SNOWPLOW_ANALYTICS_COLLECTOR_URL,
            postPath: SNOWPLOW_ANALYTICS_POST_PATH,
          },
        );
      }

      accounts.length &&
        accounts.forEach((account, index) => {
          const { appId, collectorUrl, postPath } = account;
          const config: TrackerConfiguration = {
            appId: appId,
            cookieSameSite: 'Lax',
            cookieSecure: true,
            discoverRootDomain: true,
            encodeBase64: true,
            plugins: [LinkClickTrackingPlugin()],
            stateStorageStrategy: 'cookieAndLocalStorage',
            postPath: postPath,
          };

          try {
            newTracker(account.trackerName, collectorUrl, config);
            enableActivityTracking(
              {
                minimumVisitLength: 30,
                heartbeatDelay: 10,
              },
              [defaultInstanceTracker],
            );
            enableLinkClickTracking();

            setAnalytics(({ providers = {} }) => ({
              providers: {
                ...providers,
                sp: {
                  isInitialized: true,
                  trackers: [
                    ...(providers.sp?.trackers || []),
                    { appId, collectorUrl },
                  ],
                },
              },
            }));

            debug && debugSnowplow('initialized with\n%o', { data: config });
          } catch (error) {
            debug &&
              debugSnowplow('failed with\n%o', {
                data: error,
                logger: console.error, // eslint-disable-line no-console
              });
            return false;
          }
        });

      try {
        setUserId(`${userId}`);
        addGlobalContexts([
          {
            schema: SNOWPLOW_SCHEMAS.company,
            data: {
              id: parseInt(analyticsParams?.companyId ?? 0, 10),
            },
          },
          {
            schema: SNOWPLOW_SCHEMAS.user,
            data: {
              id: parseInt(analyticsParams?.userId ?? 0, 10),
            },
          },
        ]);
      } catch (error) {
        debugSnowplow('failed with\n%o', {
          data: error,
          logger: console.error, // eslint-disable-line no-console
        });

        return false;
      }
    };

    !analytics.providers?.ga?.isInitialized && initGA(ga);
    !analytics.providers?.sp?.isInitialized && initSP(sp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analytics.providers, analyticsParams]);

  useEffect(() => {
    return () => clearGlobalContexts();
  }, []);

  const value = useMemo(() => {
    const logEvent = ({ event, data }: EventLoggerType) => {
      const { provider = 'ga' } = event;

      if (provider === 'ga') {
        const {
          action = '',
          category = '',
          label = '',
          value = undefined,
        } = event;

        if (isGAEnabled && analytics.providers?.ga?.isInitialized) {
          ReactGA.event(
            {
              category,
              action,
              label,
              value,
            },
            analytics.providers?.ga?.accountNames,
          );
        }

        // Enable Snoplow tracking GA for parity
        // @TODO refactor after removing completely GA
        trackStructEvent({ category, action, label, value }, [
          legacyInstanceTracker,
        ]);
      }

      if (provider === 'sp' && analytics.providers?.sp?.isInitialized) {
        const _snowplowFiltersData = () => {
          const {
            boardGroups,
            contentTypes,
            dateRange,
            priorities,
            rivalIds,
            sort,
            sources,
            states,
            topics,
          } = filtersFromURL;

          return {
            sort: sort || Sort.NEWEST,
            dateRange,
            boardGroups: csvToArray(boardGroups),
            rivalIds: csvToArray(rivalIds),
            states: csvToArray(states),
            priorities: csvToArray(priorities),
            sources: csvToArray(sources),
            contentTypes: csvToArray(contentTypes),
            topics: csvToArray(topics),
          };
        };
        const SNOWPLOW_EVENT_MAP: Record<string, any> = {
          ask_klue_interaction: (
            action: string,
            eventData: Record<string, any>,
          ) => {
            const {
              id,
              appType,
              text,
              threadId,
              dealTipsId,
              page,
              textFeedback,
              isAskKlueFeedbackDialog,
            } = eventData;
            return {
              type: action,
              id,
              appType,
              text,
              threadId,
              dealTipsId,
              page,
              textFeedback,
              isAskKlueFeedbackDialog,
            };
          },
          intel_action: (action: string, eventData: Record<string, any>) => {
            const triageMode = isInTriageMode();
            const intelView = triageMode ? 'triage' : 'list';
            const label = eventData?.label;
            const view = eventData?.view ? eventData?.view : intelView;
            const rank = triageMode ? 1 : eventData?.rank;

            return action && eventData
              ? { type: action, view, label, rank }
              : {};
          },
          composer_card_action: (
            action: string,
            eventData: Record<string, any>,
          ) => {
            const feedback_sentiment = eventData?.feedback_sentiment;
            const feedback_text = eventData?.feedback_text;

            return action && eventData
              ? { type: action, feedback_sentiment, feedback_text }
              : {};
          },
          search_action: (action: string, eventData: Record<string, any>) => {
            const generatedResult = eventData?.generatedResult;
            const highlightedText = eventData?.highlightedText;
            const feedbackSentiment = eventData?.feedbackSentiment;
            const feedbackText = eventData?.feedbackText;
            const resultCount = eventData?.resultCount;

            return action && eventData
              ? {
                  type: action,
                  generatedResult,
                  highlightedText,
                  feedbackSentiment,
                  feedbackText,
                  resultCount,
                }
              : {};
          },
          impression: (action: string, eventData: Record<string, any>) => {
            // TODO: Will be refactored to new approach soon
            const triageMode = isInTriageMode();
            const intelView = triageMode ? 'triage' : 'list';
            const intelRank = triageMode ? 1 : eventData?.rank;
            const view = eventData?.view ? eventData.view : intelView;
            const rank =
              action === AnalyticsAction.boardCard
                ? eventData?.rank
                : intelRank;

            return action && eventData ? { type: action, view, rank } : {};
          },
          card_interaction: (
            action: string,
            eventData: Record<string, any>,
          ) => {
            const view = eventData?.view;
            const label = eventData?.label;
            const cardType = eventData?.cardType;

            return action && eventData
              ? { type: action, view, label, cardType }
              : {};
          },
          insights_action: (action: string, eventData: Record<string, any>) => {
            const hasOverviewCard = eventData?.hasOverviewCard;
            const totalCardsCreated = eventData?.totalCardsCreated;
            if (action && eventData) {
              return {
                type: action,
                hasOverviewCard,
                totalCardsCreated,
              };
            }
            return action ? { type: action } : {};
          },
          integration_card_action: (action: AnalyticsActionType) => {
            switch (action) {
              case 'click_install':
              case 'click_uninstall':
              case 'click_connect':
              case 'click_waitlist':
              case 'click_contact_support':
              case 'click_resume_install':
                return { type: action };
              default:
                return {};
            }
          },
          data_sync_configuration_complete: (
            _: string,
            eventData: { firstTimeSetup?: boolean; fieldsChanged?: number },
          ) => {
            return {
              firstTimeSetup: eventData?.firstTimeSetup,
              fieldsChanged: eventData?.fieldsChanged,
            };
          },
          data_sync_auth_modified: (
            _: string,
            eventData: { firstTimeSetup?: boolean },
          ) => {
            return {
              firstTimeSetup: eventData?.firstTimeSetup,
            };
          },
          call_data_sync_auth_modified: (
            _: string,
            eventData: { firstTimeSetup?: boolean },
          ) => {
            return { firstTimeSetup: eventData?.firstTimeSetup };
          },
          call_data_sync_configuration_complete: (
            _: string,
            eventData: {
              firstTimeSetup?: boolean;
              trackersChanged?: boolean;
              aliasesChanged?: boolean;
            },
          ) => {
            return {
              firstTimeSetup: eventData?.firstTimeSetup,
              trackersChanged: eventData?.trackersChanged,
              aliasesChanged: eventData?.aliasesChanged,
            };
          },
          call_data_sync_toggled: (
            _: string,
            eventData: { enabled?: boolean },
          ) => {
            return {
              firstTimeSetup: eventData?.enabled,
            };
          },
          research_module_action: (
            action: 'share_feedback',
            data: ResearchModuleActionType,
          ) => {
            return {
              type: action,
              ...data,
              sentiment: data.sentiment || undefined, // null is not valid
              messages: data.messages.map((message) => ({
                ...message,
                sources: message.sources
                  ?.map(({ source_document_id: id, doc_type: type }) => ({
                    id,
                    type,
                  }))
                  .filter(({ id, type }) => id && type) as
                  | { id: string; type: string }[]
                  | undefined,
              })),
            };
          },
          unleash_feature_flag: (
            _: string,
            eventData: {
              eventType: string;
              eventId: string;
              enabled: boolean;
              featureName: string;
            },
          ) => {
            return {
              eventType: eventData?.eventType,
              eventId: eventData?.eventId,
              enabled: eventData?.enabled,
              featureName: eventData?.featureName,
            };
          },
          competitive_dashboard_action: (
            _: string,
            eventData: Record<string, any>,
          ) => {
            return {
              type: eventData?.competitiveDashboard.type,
            };
          },
          landing_page_action: (
            action: string,
            eventData: Record<string, any>,
          ) => {
            const filteredEventData = pick(eventData, ['contentAvailable']);
            return {
              type: action,
              ...filteredEventData,
            };
          },
        };
        const SNOWPLOW_ENTITY_MAP: Record<string, any> = {
          intelfilter: (eventData: Record<string, any>) => {
            const { intelfilter } = eventData;
            return { ..._snowplowFiltersData(), ...intelfilter };
          },
          integration: ({ slug }: { slug: Integration['slug'] }) => {
            return { slug };
          },
          unleash: ({ eventId }: { eventId: string }) => {
            return { eventId };
          },
        };
        const SNOWPLOW_ENTITY_KEYS: Record<string, any> = {
          ask_klue_interaction: ['board', 'ask_klue_suggest'],
          intel_action: {
            common: ['intel', 'intelfilter'],
            board: ['board'],
            tag: ['tag'],
            why_it_matters: ['comment'],
          },
          insights_action: {
            common: [],
            filter: ['reviewfilter'],
            review: ['review'],
            aspect: ['aspect'],
            insight_source: ['insight', 'reviewfilter', 'insightfilter'],
            create_card: ['insight', 'lane', 'reviewfilter', 'insightfilter'],
            create_summary_card: ['board', 'reviewfilter'],
            feedback: ['insight', 'reviewfilter', 'insightfilter'],
            insight_filter: ['insight', 'insightfilter'],
          },
          impression: {
            common: ['intel', 'intelfilter'],
          },
          integration_card_action: ['integration'],
          data_sync_configuration_complete: ['integration'],
          data_sync_auth_modified: ['integration'],
          call_data_sync_auth_modified: ['integration'],
          call_data_sync_configuration_complete: ['integration'],
          call_data_sync_toggled: ['integration'],
          composer_card_action: ['composer_card'],
          unleash_feature_flag: [],
          competitive_dashboard_action: ['competitiveDashboard', 'board'],
          landing_page_action: [
            'supported_rival',
            'board',
            'insight',
            'battlecard',
          ],
        };
        const createSnowplowEventContext = (
          entityKeys: string[] = [],
          eventData: Record<string, any> = {},
        ) => {
          return entityKeys.map((entityName = '') => {
            const schema = SNOWPLOW_SCHEMAS[entityName];
            const dataLookup = SNOWPLOW_ENTITY_MAP[entityName];
            const data = dataLookup
              ? dataLookup(eventData)
              : eventData[entityName] ?? {};

            return {
              schema,
              data,
            };
          });
        };
        const SNOWPLOW_EVENT_CONTEXT_MAP: Record<string, any> = {
          ask_klue_interaction: (
            action: string,
            eventData: Record<string, any>,
          ) => {
            const contexts: { schema: string; data: any }[] = [];
            if (
              action &&
              eventData &&
              action === AnalyticsAction.renderAskSuggest
            ) {
              const { askKlueSuggest } = eventData;
              if (!isEmpty(askKlueSuggest)) {
                askKlueSuggest.forEach((suggest: any) => {
                  contexts.push(
                    createSnowplowEventContext(['ask_klue_suggest'], {
                      ask_klue_suggest: {
                        template: suggest.template,
                        text: suggest.text,
                      },
                    })[0],
                  );
                });
                askKlueSuggest.forEach((suggest: any) => {
                  contexts.push(
                    createSnowplowEventContext(['board'], {
                      board: {
                        id: parseInt(suggest.rivalId),
                        idType: 'rival',
                        viewOrder: suggest.rivalRank,
                      },
                    })[0],
                  );
                });
              }
            }

            return contexts;
          },
          intel_action: (action: string, eventData: Record<string, any>) => {
            if (action && eventData) {
              const customEntities = eventData?.entities ?? [];
              const commonEntities =
                SNOWPLOW_ENTITY_KEYS.intel_action?.common ?? [];
              const eventEntities =
                SNOWPLOW_ENTITY_KEYS.intel_action[
                  action.replace(/^(?:add|edit|remove)_(.*)$/gi, '$1')
                ] ?? [];

              const entities = !!customEntities.length
                ? customEntities
                : commonEntities;

              return createSnowplowEventContext(
                [...entities, ...eventEntities],
                eventData,
              );
            }

            return [];
          },
          impression: (action: string, eventData: Record<string, any>) => {
            if (action && eventData) {
              const commonEntities =
                SNOWPLOW_ENTITY_KEYS.impression?.common ?? [];
              const customEntities = eventData?.entities ?? [];
              const entities = !!customEntities.length
                ? customEntities
                : commonEntities;

              return createSnowplowEventContext([...entities], eventData);
            }
            return [];
          },
          card_interaction: (
            action: string,
            eventData: Record<string, any>,
          ) => {
            if (action && eventData) {
              const customEntities = eventData?.entities ?? [];
              return createSnowplowEventContext([...customEntities], eventData);
            }
            return [];
          },
          insights_action: (action: string, eventData: Record<string, any>) => {
            if (action) {
              switch (action) {
                case AnalyticsAction.copyTopicSummary:
                case AnalyticsAction.updateFilter:
                case AnalyticsAction.generateSummary:
                  return createSnowplowEventContext(
                    SNOWPLOW_ENTITY_KEYS.insights_action?.filter ?? [],
                    eventData,
                  );
                case AnalyticsAction.pinReview:
                case AnalyticsAction.unpinReview:
                case AnalyticsAction.visitSource:
                  return createSnowplowEventContext(
                    SNOWPLOW_ENTITY_KEYS.insights_action?.review ?? [],
                    eventData,
                  );
                case AnalyticsAction.hideAspect:
                case AnalyticsAction.unhideAspect:
                  return createSnowplowEventContext(
                    SNOWPLOW_ENTITY_KEYS.insights_action?.aspect ?? [],
                    eventData,
                  );
                case AnalyticsAction.sourceButtonClick:
                  return createSnowplowEventContext(
                    SNOWPLOW_ENTITY_KEYS.insights_action?.insight_source ?? [],
                    eventData,
                  );
                case AnalyticsAction.createInsightCard: {
                  const customEventList: {
                    schema: string;
                    data: Record<string, any>;
                  }[] = [];

                  if (
                    Object.prototype.hasOwnProperty.call(eventData, 'card') &&
                    eventData.card
                  ) {
                    if (Array.isArray(eventData.card)) {
                      eventData.card.forEach(
                        (eventCard: Record<string, any>) => {
                          customEventList.push({
                            schema: SNOWPLOW_SCHEMAS['card_103'],
                            data: eventCard,
                          });
                        },
                      );
                    } else {
                      customEventList.push({
                        schema: SNOWPLOW_SCHEMAS['card_103'],
                        data: eventData.card,
                      });
                    }
                  }
                  return [
                    ...customEventList,
                    ...createSnowplowEventContext(
                      SNOWPLOW_ENTITY_KEYS.insights_action?.create_card ?? [],
                      eventData,
                    ),
                  ];
                }
                case AnalyticsAction.positiveFeedback:
                case AnalyticsAction.negativeFeedback:
                  return createSnowplowEventContext(
                    SNOWPLOW_ENTITY_KEYS.insights_action?.feedback ?? [],
                    eventData,
                  );
                case AnalyticsAction.updateInsightFilter:
                  return createSnowplowEventContext(
                    SNOWPLOW_ENTITY_KEYS.insights_action?.insight_filter ?? [],
                    eventData,
                  );
                case AnalyticsAction.createTopicSummaryCard:
                  return createSnowplowEventContext(
                    SNOWPLOW_ENTITY_KEYS.insights_action?.create_summary_card ??
                      [],
                    eventData,
                  );
                default:
                  return [];
              }
            }
            return [];
          },
          integration_card_action: (
            _: AnalyticsActionType,
            eventData: { slug: string },
          ) => {
            return createSnowplowEventContext(
              SNOWPLOW_ENTITY_KEYS.integration_card_action,
              eventData,
            );
          },
          composer_card_action: (
            _: AnalyticsActionType,
            eventData: { id: number; generation_id: string; html: string },
          ) => {
            return createSnowplowEventContext(
              SNOWPLOW_ENTITY_KEYS.composer_card_action,
              eventData,
            );
          },
          search_action: (action: AnalyticsActionType, eventData: any) => {
            if (action && eventData) {
              const customEntities = eventData?.entities ?? [];
              return createSnowplowEventContext([...customEntities], eventData);
            }
            return [];
          },
          data_sync_configuration_complete: (
            _: AnalyticsActionType,
            eventData: { slug: string },
          ) => {
            return createSnowplowEventContext(
              SNOWPLOW_ENTITY_KEYS.data_sync_configuration_complete,
              eventData,
            );
          },
          data_sync_auth_modified: (
            _: AnalyticsActionType,
            eventData: { slug: string },
          ) => {
            return createSnowplowEventContext(
              SNOWPLOW_ENTITY_KEYS.data_sync_auth_modified,
              eventData,
            );
          },
          call_data_sync_configuration_complete: (
            _: AnalyticsActionType,
            eventData: { slug: string },
          ) => {
            return createSnowplowEventContext(
              SNOWPLOW_ENTITY_KEYS.call_data_sync_configuration_complete,
              eventData,
            );
          },
          call_data_sync_auth_modified: (
            _: AnalyticsActionType,
            eventData: { slug: string },
          ) => {
            return createSnowplowEventContext(
              SNOWPLOW_ENTITY_KEYS.call_data_sync_auth_modified,
              eventData,
            );
          },
          unleash_feature_flag: (
            _: AnalyticsActionType,
            eventData: { eventId: string },
          ) => {
            return createSnowplowEventContext(
              SNOWPLOW_ENTITY_KEYS.unleash_feature_flag ?? [],
              eventData,
            );
          },
          research_module_action: () => [],
          competitive_dashboard_action: (
            action: string,
            eventData: Record<string, any>,
          ) => {
            if (action && eventData) {
              const { competitiveDashboard, board, boards } = eventData;
              const contexts: { schema: string; data: any }[] = [];
              if (competitiveDashboard.type === 'view') {
                if (!isEmpty(boards)) {
                  boards?.forEach((board: any) => {
                    contexts.push(
                      createSnowplowEventContext(['board'], {
                        board: {
                          id: board.id,
                          idType: board.idType,
                          viewOrder: board.viewOrder,
                          associatedRevenue: Math.round(
                            board.associatedRevenue || 0,
                          ),
                          associatedDeals: board.associatedDeals,
                        },
                      })[0],
                    );
                  });
                }
              } else {
                contexts.push(
                  createSnowplowEventContext(['board'], {
                    board: {
                      id: board.id,
                      idType: board.idType,
                      viewOrder: board.viewOrder,
                      associatedRevenue: Math.round(
                        board.associatedRevenue || 0,
                      ),
                      associatedDeals: board.associatedDeals,
                    },
                  })[0],
                );
              }

              return contexts;
            }
          },
          landing_page_action: (
            _: AnalyticsActionType,
            eventData: Record<string, any>,
          ) => {
            const definedEntities =
              SNOWPLOW_ENTITY_KEYS.landing_page_action.filter(
                (entity: string) => entity in eventData,
              );
            return createSnowplowEventContext([...definedEntities], eventData);
          },
        };
        const createSnowplowEvent = (
          event: AnalyticsEvent,
          eventData?: Record<string, any[]>,
        ): SnowplowAnalyticsEvent => {
          const { action = '', category = '' } = event;

          const schema = SNOWPLOW_SCHEMAS[category];
          const data = SNOWPLOW_EVENT_MAP[category](action, eventData);
          const context = SNOWPLOW_EVENT_CONTEXT_MAP[category](
            action,
            eventData,
          );

          return {
            event: {
              schema,
              data,
            },
            context,
          };
        };
        const snowplowEvent = createSnowplowEvent(event, data);

        trackSelfDescribingEvent(snowplowEvent, [defaultInstanceTracker]);

        analyticsParams?.debug &&
          debugSnowplow('called trackSelfDescribingEvent with\n%o', {
            data: snowplowEvent,
          });
      }
    };

    const addTracker = (trackerConfig: TrackerType) => {
      if (!isGAEnabled) return null;

      const { provider, config } = trackerConfig;

      if (provider === 'ga' && analytics.providers?.ga?.isInitialized) {
        ReactGA.addTrackers([
          {
            trackingId: config?.accountId,
            gaOptions: {
              name: config?.accountName,
            },
            debug: DEBUG_ANALYTICS,
            titleCase: false,
          },
        ]);
        setAnalytics(({ providers = {} }) => ({
          providers: {
            ...providers,
            ga: {
              isInitialized: Boolean(providers.ga?.isInitialized),
              accountNames: [
                ...(providers.ga?.accountNames || []),
                config?.accountName,
              ],
            },
          },
        }));
      }
    };

    const removeTracker = (tracker: TrackerType) => {
      if (!isGAEnabled) return null;

      const { config, provider } = tracker;

      if (provider === 'ga' && analytics.providers?.ga?.isInitialized) {
        setAnalytics(({ providers = {} }) => ({
          providers: {
            ...providers,
            ga: {
              isInitialized: Boolean(providers.ga?.isInitialized),
              accountNames: (providers.ga?.accountNames || []).filter(
                (name) => name !== config?.accountName,
              ),
            },
          },
        }));
      }
    };

    return {
      logEvent,
      addTracker,
      removeTracker,
      analytics,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analytics.providers, filtersFromURL]);

  useEffect(() => {
    const setTrackingParams = (path?: string) => {
      if (!isGAEnabled || !path) return null;

      const isIframe = window && window.location !== window.parent.location;
      const queryString = path.substring(path.indexOf('?') + 1);
      const defaultCampaignMedium = isIframe ? 'iframe' : null;
      const defaultCampaignContent = getCampaignContentFromUrl(path);
      const defaultCampaignParams = {
        campaignSource: 'unknown',
        campaignContent: defaultCampaignContent,
      };

      if (/utm_/.test(queryString)) {
        const oldUrl = window && window.location.href;
        const trackingParams = {
          utm_source: '',
          utm_medium: '',
          utm_content: '',
        };

        queryString.split('&').forEach((param) => {
          const [firstPair, secondPair] = param.split('=');

          trackingParams[firstPair as keyof typeof trackingParams] =
            decodeURIComponent(secondPair);
        });

        const {
          utm_source: campaignSource,
          utm_medium: campaignMediumParam,
          utm_content: campaignContent,
        } = trackingParams;

        const campaignMedium =
          isIframe && !campaignMediumParam
            ? defaultCampaignMedium
            : campaignMediumParam;
        const newUrl = cleanGoogleAnalyticsUrlParams(oldUrl);

        if (newUrl !== oldUrl) {
          analytics.providers?.ga?.isInitialized &&
            ReactGA.set(
              { campaignSource, campaignMedium, campaignContent },
              analytics.providers?.ga?.accountNames,
            );
        }
      } else if (isIframe) {
        analytics.providers?.ga?.isInitialized &&
          ReactGA.set(
            { ...defaultCampaignParams, campaignMedium: defaultCampaignMedium },
            analytics.providers?.ga?.accountNames,
          );
      }
    };

    const pageTracker: PageTrackerType = ({ path }) => {
      setTrackingParams(path);

      if (isGAEnabled && analytics.providers?.ga?.isInitialized) {
        ReactGA.pageview(path, analytics.providers?.ga?.accountNames);
      }

      if (
        analytics.providers?.sp?.isInitialized &&
        !IGNORED_SNOWPLOW_PATHS_REGEX.test(path)
      ) {
        trackPageView({}, [defaultInstanceTracker]);

        analyticsParams?.debug &&
          debugSnowplow('called `trackPageView` with\n%o', { data: { path } });
      }
    };

    pageTracker({
      path: `${location.pathname}${location.search}`,
    });
  }, [
    analytics.providers,
    analyticsParams?.debug,
    isGAEnabled,
    location.pathname,
    location.search,
  ]);

  return (
    <TrackingContext.Provider value={value}>
      {children}
    </TrackingContext.Provider>
  );
}

const useAnalytics = () => useContext(TrackingContext);

export { AnalyticsProvider, useAnalytics };
