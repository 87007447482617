import {
  Anchor,
  Box,
  Button,
  Heading,
  Modal,
  PhosphorIcon,
  Text,
  TextInput,
  tokens,
} from '@kluein/klue-ui';
import { format } from 'date-fns';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isValidURL } from 'lib/utils';

import useKlueCardSources from '../../hooks/use-klue-card-sources/useKlueCardSources';
import { INPUT_PLACEHOLDER } from '../../KlueCard.utils';

export default function KlueCardSourcesModal(props: {
  cardId: number;
  handleClose: () => void;
}) {
  const { cardId, handleClose } = props;
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState<string | undefined>('');
  const { cardSourcesList, isLoading, addSource, deleteSource } =
    useKlueCardSources({ cardId });

  const onClose = () => {
    handleClose();
  };
  const handleAddSource = (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent,
  ) => {
    e?.preventDefault();
    if (
      !isValidURL(inputValue) ||
      Object.values(cardSourcesList || {}).find(
        (source) => source.permalink === inputValue,
      )
    ) {
      setError('invalid URL');
      return;
    } else {
      setError('');
    }

    if (inputValue) {
      addSource(inputValue)
        .then(() => {
          setInputValue('');
        })
        .catch(() => {
          setError('invalid URL');
          return;
        });
    }
  };
  const handleDeleteSource = (id: number) => {
    deleteSource(id).catch(() => {
      setError('Failed to delete source');
    });
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setError('');
  };

  return (
    <Modal
      showCloseButton={false}
      width="xxxlarge"
      onClose={onClose}
      background={tokens.color.neutral.white.main}
      responsive
    >
      <Modal.Header background={tokens.color.primary.main}>
        <Box direction="row" gap="small" align="center">
          <PhosphorIcon.Info />
          <Heading
            size="medium"
            margin="none"
            level="2"
            color={tokens.color.primary.contrastmain}
          >
            {t('Card:sources.title')}
          </Heading>
        </Box>
        <Button
          variant="plain"
          onClick={onClose}
          icon={<PhosphorIcon.X color={tokens.color.primary.contrastmain} />}
        />
      </Modal.Header>
      <Modal.Content>
        <Text
          size="medium"
          color={tokens.color.neutral.black.main}
          margin={{ vertical: 'small' }}
        >
          {t('Card:sources.addSources')}
        </Text>
        <form onSubmit={handleAddSource}>
          <Box direction="row" justify="end" gap="small">
            <TextInput
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={true}
              placeholder={INPUT_PLACEHOLDER}
              title={error ? error : INPUT_PLACEHOLDER}
              value={inputValue}
              onChange={handleInputChange}
              style={{
                borderColor: error
                  ? tokens.color.status.error['800']
                  : 'initial',
              }}
            />
            <Box flex={false}>
              <Button
                type="submit"
                variant="flat-outlined"
                onClick={handleAddSource}
                label={t('Common:add')}
                disabled={inputValue.trim() === '' || isLoading}
              />
            </Box>
          </Box>
        </form>
        <Box
          margin={{ vertical: 'xlarge' }}
          height={{ max: 'medium' }}
          overflow="auto"
        >
          {Object.values(cardSourcesList || {})
            .reverse()
            .map((source, idx) => (
              <Box
                flex={false}
                direction="row"
                gap="large"
                key={source.id}
                align="center"
                justify="between"
                pad={{ vertical: 'small' }}
                border={
                  idx === 0
                    ? undefined
                    : {
                        side: 'top',
                        size: 'xsmall',
                        color: tokens.color.neutral.lightgrey.main,
                      }
                }
              >
                <Box
                  flex={{ grow: 1, shrink: 1 }}
                  direction="row"
                  gap="small"
                  align="center"
                >
                  <Box flex={false}>
                    <PhosphorIcon.Info
                      size={tokens.size.ComponentIconSize.large}
                      weight="regular"
                    />
                  </Box>
                  <Box>
                    <Anchor
                      href={source.permalink}
                      target="_blank"
                      variant="undecorated-primary"
                    >
                      <Text truncate>{source.permalink}</Text>
                    </Anchor>
                  </Box>
                </Box>
                <Box flex={false}>
                  <Text truncate>
                    {format(
                      new Date(source.createdAt),
                      'LLL dd, yyyy',
                    ).toLocaleUpperCase()}
                  </Text>
                </Box>
                <Box flex={false}>
                  <Text truncate>{source.prettyName}</Text>
                </Box>

                <Box flex={false}>
                  <Button
                    variant="plain"
                    onClick={() => handleDeleteSource(source.id)}
                    a11yTitle={t('Card:sources.removeSource')}
                    tip={{
                      content: t('Card:sources.removeSource'),
                      variant: 'small',
                      color: 'dark',
                      dropProps: { align: { bottom: 'top' } },
                    }}
                  >
                    <PhosphorIcon.Trash
                      color={tokens.color.status.error.main}
                      size={tokens.size.ComponentIconSize.large}
                      weight="regular"
                    />
                  </Button>
                </Box>
              </Box>
            ))}
        </Box>
        <Box direction="row" justify="end" margin={{ top: 'medium' }}>
          <Button
            variant="flat-outlined-form"
            onClick={onClose}
            disabled={isLoading}
            label={t('Common:done')}
          />
        </Box>
      </Modal.Content>
    </Modal>
  );
}
