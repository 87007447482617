import { getExtensions } from '@kluein/content-editor/extensions';
import { ReactRenderer } from '@kluein/content-editor/renders';
import { Box, Card, Paragraph, Skeleton } from '@kluein/klue-ui';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import {
  CARD_CONTENT_ALLOWED_TAGS,
  CARD_CONTENT_ALLOWED_ATTR,
} from 'klue-html/constants';
import sanitize from 'klue-html/sanitizer';
import {
  AnalyticsCategory,
  AnalyticsAction,
  type EventLoggerType,
} from 'lib/analytics/AnalyticsProvider.types';
import { KLUE_CARD_NO_ZOOM_IMG } from 'lib/constants';
import TEST_IDS from 'test-ids';

import { DynamicBlock as DynamicBlockExtension } from '../../../card-editor/extensions/dynamic-blocks';
import DynamicBlock from '../../blocks/dynamicBlock/DynamicBlock';
import { transformHTML } from '../../transformers';

import type { UseCardInteractionLogType } from '../../hooks/use-card-interaction-log/useCardInteractionLog';
import type { CardDataType } from 'api/api.types';
import type { TextJson, Formula } from 'klue-html/formulas/formulas.types';

type KlueCardContentProps = {
  hasLoaded: boolean;
  isCardViewerContent?: boolean;
  cardData?: CardDataType;
  initialHeight?: string | number;
  cardId?: number;
  logEventLabel: string;
  rank?: number | null;
  logEvent: (data: EventLoggerType) => void;
  logCardInteraction: UseCardInteractionLogType;
  KLUE_CARD_CUSTOM_MARGIN_CONTENT: string;
  KLUE_CARD_INTERNAL_GUTTER: number;
  isNewCardRendererEnabled: boolean;
};

const ContentRenderer: React.FC<{
  textJson: TextJson[];
  logEventLabel: string;
  cardId?: number;
}> = React.memo(({ textJson, logEventLabel, cardId }) => {
  const renderContentByType = React.useCallback(
    ({ block, blockKey }: { block: TextJson; blockKey: string }) => {
      const renderers = {
        html: () => {
          const sanitized = sanitize(block?.data as string, {
            ALLOWED_TAGS: CARD_CONTENT_ALLOWED_TAGS,
            ADD_ATTR: CARD_CONTENT_ALLOWED_ATTR,
          });

          return (
            <div key={blockKey}>
              {transformHTML(sanitized, logEventLabel, cardId)}
            </div>
          );
        },
        formula: () => (
          <DynamicBlock
            key={blockKey}
            formula={block?.data as Formula}
            cardId={cardId}
          />
        ),
      };

      return renderers;
    },
    [cardId, logEventLabel],
  );

  return (
    <>
      {textJson?.map((block, i: number) => {
        const blockKey = `${block.type}_${i}`;
        return renderContentByType({
          block,
          blockKey,
        })[block.type]();
      })}
    </>
  );
});

ContentRenderer.displayName = 'ContentRenderer';

const FallbackComponent = ({ cardData }: { cardData: CardDataType }) => {
  const { t } = useTranslation();

  return <Paragraph>{t('Card:fallback.renderError')}</Paragraph>;
};

const createExtensions = (eventTrackers: any, cardId?: number) => [
  ...getExtensions({
    image: {
      enableZoom: true,
      zoomProps: { title: '' },
      events: eventTrackers,
    },
  }),
  DynamicBlockExtension.configure({
    cardId,
    renderOnly: true,
  }),
];

export const KlueCardContent: React.FC<KlueCardContentProps> = React.memo(
  ({
    hasLoaded,
    isCardViewerContent,
    isNewCardRendererEnabled,
    cardData,
    initialHeight = 'auto',
    cardId,
    rank,
    logEventLabel,
    logEvent,
    logCardInteraction,
    KLUE_CARD_CUSTOM_MARGIN_CONTENT,
    KLUE_CARD_INTERNAL_GUTTER,
  }) => {
    const contentStyle = React.useMemo(
      () => ({
        margin: isCardViewerContent
          ? KLUE_CARD_CUSTOM_MARGIN_CONTENT
          : `${KLUE_CARD_INTERNAL_GUTTER}px`,
      }),
      [
        isCardViewerContent,
        KLUE_CARD_CUSTOM_MARGIN_CONTENT,
        KLUE_CARD_INTERNAL_GUTTER,
      ],
    );

    const skeletonBoxStyle = React.useMemo(
      () => ({
        min: String(initialHeight),
      }),
      [initialHeight],
    );

    if (!hasLoaded) {
      return (
        <Card.Content style={{ flexGrow: 1 }}>
          <Box margin="large" height={skeletonBoxStyle}>
            <Skeleton height={'200px'} animated={true} />
          </Box>
        </Card.Content>
      );
    }

    const hasNewFormatDynamicBlocks = cardData?.jsonData?.content?.some(
      (block) => block.type === 'dynamicBlock',
    );

    const shouldUseNewRenderer =
      isNewCardRendererEnabled &&
      (hasNewFormatDynamicBlocks || !cardData?.hasDynamicBlocks);

    const eventTrackers = {
      onZoom: (target: HTMLImageElement) => {
        let zoomId = 'img0';
        const index = Array.from(
          target
            ?.closest(`[data-test-id="${TEST_IDS.klueCard.content}"]`)
            ?.querySelectorAll(`img:not(.${KLUE_CARD_NO_ZOOM_IMG}`) || [],
        ).indexOf(target);

        if (index >= 0) {
          zoomId = `img${index}`;
        }

        logEvent({
          event: {
            category: AnalyticsCategory.zoom,
            action: AnalyticsAction.view,
            label: `${logEventLabel} : ${zoomId}`,
          },
        });

        if (cardId) {
          logCardInteraction({
            action: AnalyticsAction.image,
            label: zoomId,
            cardId,
            rank,
          });
        }
      },
    };

    if (!cardData) {
      return null;
    }

    const extensions = createExtensions(eventTrackers, cardId);

    return (
      <Card.Content style={{ flexGrow: 1 }}>
        <div
          data-test-id={TEST_IDS.klueCard.content}
          className={`chromatic-ignore ${
            !shouldUseNewRenderer ? 'card-static-html_body' : ''
          }`}
          style={contentStyle}
        >
          {shouldUseNewRenderer ? (
            <ErrorBoundary fallback={<FallbackComponent cardData={cardData} />}>
              <div className="klue-content-service-editor">
                <ReactRenderer
                  content={cardData?.jsonData || cardData?.textHtml || ''}
                  extensions={extensions}
                />
              </div>
            </ErrorBoundary>
          ) : (
            <ContentRenderer
              textJson={
                Array.isArray(cardData?.textJson) ? cardData.textJson : []
              }
              logEventLabel={logEventLabel}
              cardId={cardId}
            />
          )}
        </div>
      </Card.Content>
    );
  },
);

KlueCardContent.displayName = 'KlueCardContent';

export default KlueCardContent;
