import { Box, Text, tokens, CheckBox } from '@kluein/klue-ui';
import { useTranslation } from 'react-i18next';

import { CountText } from 'components/common/count-text';

import type { BattlecardsType } from 'api/api.types';

type CardBattlecardItemProps = {
  battlecard: Partial<BattlecardsType> & { id: number };
  isDefault?: boolean;
  checked: boolean;
  onToggleChecked: (battlecardId: number) => void;
  cardCounts: number;
};

const CardBattlecardItem = ({
  battlecard,
  isDefault,
  checked,
  onToggleChecked,
  cardCounts,
}: CardBattlecardItemProps) => {
  const { id, title } = battlecard;
  const { t } = useTranslation();
  const maxCardCount = 8;
  const countString = `${cardCounts} / ${maxCardCount}`;
  const disabled = cardCounts === maxCardCount && !checked;
  return (
    <Box width="100%" margin={{ bottom: 'small' }} flex={false}>
      {isDefault ? (
        <Text
          size="small"
          margin={{ left: 'xxlarge' }}
          color={tokens.color.primitives.grey[800]}
        >
          {t('Battlecard:default')}
        </Text>
      ) : null}
      <Box direction="row" align="center" justify="between" gap="small">
        <Box direction="row" align="center" justify="between" gap="small">
          <CheckBox
            checked={checked}
            onChange={() => onToggleChecked(id)}
            disabled={disabled}
          />
          <Text
            onClick={() => {
              if (disabled) return;

              onToggleChecked(id);
            }}
            fontWeight={isDefault ? 'semibold' : 'normal'}
            size="large"
            color={
              disabled
                ? tokens.color.primitives.grey[800]
                : tokens.color.neutral.black.main
            }
            style={{
              cursor: disabled ? 'not-allowed' : 'pointer',
            }}
          >
            {title}
          </Text>
        </Box>
        <CountText>
          <Text>{countString}</Text>
        </CountText>
      </Box>
    </Box>
  );
};

export default CardBattlecardItem;
