import {
  Main,
  Image,
  Text,
  Heading,
  Anchor,
  Box,
  theme,
  type FillType,
  type AlignType,
  type JustifyContentType,
  type HeadingProps,
  type TextProps,
  tokens,
} from '@kluein/klue-ui';
import styled from 'styled-components';

import { APP_NAV_BAR_HEIGHT } from 'components/layout/app-nav-bar/constants';

export type FallbackViewType = {
  fullscreen?: boolean;
  image?: string;
  heading: string;
  headingProps?: HeadingProps;
  desc: string | React.ReactNode;
  descProps?: TextProps;
  linkLabel?: string;
  linkPath?: string;
  fill?: FillType;
  align?: AlignType;
  justify?: JustifyContentType;
  width?: string;
  dataTestId?: string;
};

const FullScreenWrapper = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
`;

const FallbackViewContent = ({
  image,
  heading,
  desc,
  linkLabel,
  linkPath,
  headingProps = { level: 1 },
  descProps = {},
  fill = 'horizontal',
  align = 'center',
  justify = 'center',
  width = theme.global?.size?.large,
  dataTestId,
}: Partial<FallbackViewType>) => (
  <Main fill={fill} align={align} justify={justify} data-test-id={dataTestId}>
    <Box
      align="center"
      justify="center"
      pad="medium"
      height={{ max: `calc(100vh - ${APP_NAV_BAR_HEIGHT})` }}
      style={{
        width,
        color: `${theme.global?.colors?.text}`,
      }}
    >
      {image && (
        <Box
          style={{
            width: theme.global?.size?.medium,
            fontSize: theme.paragraph?.medium?.size,
          }}
        >
          <Image
            alt={heading}
            fill
            src={image}
            style={{
              marginTop: theme.global?.edgeSize?.xxsmall,
              marginBottom: theme.global?.edgeSize?.xsmall,
            }}
          />
        </Box>
      )}
      <Heading
        size="large"
        margin={{ bottom: 'xxsmall' }}
        textAlign="center"
        lineHeight="sparse"
        {...headingProps}
      >
        {heading}
      </Heading>
      {typeof desc === 'string' ? (
        <Text
          fontFamily={tokens.fontFamily.Inter}
          textAlign="center"
          size="medium"
          {...descProps}
        >
          {desc}
        </Text>
      ) : (
        desc
      )}
      {typeof linkLabel === 'string' && linkPath && (
        <Anchor
          title={linkLabel}
          href={linkPath}
          style={{
            fontSize: theme.paragraph?.small?.size,
            marginTop: theme.global?.edgeSize?.large,
          }}
          label={linkLabel}
        />
      )}
    </Box>
  </Main>
);

export default function FallbackView({
  fullscreen = false,
  ...rest
}: FallbackViewType) {
  if (fullscreen) {
    return (
      <FullScreenWrapper fill>
        <FallbackViewContent {...rest} />
      </FullScreenWrapper>
    );
  }

  return <FallbackViewContent {...rest} />;
}
