import {
  formulaCategories,
  formulaKeyMappings,
  formulaToKeyMappings,
} from 'klue-html/formulas/constants';
import { Formulas, type Formula } from 'klue-html/formulas/formulas.types';

import type { CardType } from 'api/api.types';

export const parseDynamicBlocks = (cardData: CardType['data']): string => {
  if (!cardData.textJson) {
    return cardData?.textHtml || '';
  }

  return cardData.textJson
    .map((item) => {
      if (item.type === 'formula') {
        const formula = item.data as Formula;

        const extraAttributes = [];

        if (formula.extra?.profileId) {
          extraAttributes.push(`data-profile-id="${formula.extra.profileId}"`);
        }
        if (formula.extra?.sfdcQueryType) {
          extraAttributes.push(
            `data-sfdc-query-type="${formula.extra.sfdcQueryType}"`,
          );
        }

        return `<div
          data-block-type="dynamicBlock"
          data-formula-key="${formula.formulaType}"
          data-companies='${JSON.stringify(formula.companies)}'
          data-card-id="${formula.cardId}"
          data-formula-type="${formula.formulaType}"
          data-type="${formula.type}"
          data-block-valid="${
            formula.formulaType && formula.companies.length > 0
          }"
          data-original-formula="${encodeURIComponent(
            formula.originalFormula || '',
          )}"
          ${extraAttributes.join(' ')}
        ></div>`;
      } else if (item.type === 'html') {
        return item.data;
      }
      return '';
    })
    .join('');
};

export function parseDynamicBlocksInHtml(html: string): string {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const dynamicBlocks = doc.querySelectorAll(
    'div[data-block-type="dynamicBlock"]',
  );

  dynamicBlocks.forEach((block) => {
    const encodedFormula = block.getAttribute('data-original-formula');
    if (encodedFormula) {
      const decodedFormula = decodeURIComponent(encodedFormula);
      block.replaceWith(decodedFormula);
    }
  });

  return doc.body.innerHTML;
}

/**
 * Reconstructs a formula string based on the provided data.
 *
 * @param data - The data object containing formula type, companies, and extra attributes.
 * @returns The reconstructed formula string.
 * @description This is based on the original implementation from v1 so it's not perfect.
 */
export function reconstructFormula(data: {
  formulaType: Formulas;
  companies: (number | string)[];
  extra?: {
    sfdcQueryType?: string;
    profileId: string;
  };
}): string {
  const { formulaType, companies, extra } = data;
  const formulaObject =
    formulaKeyMappings[formulaType as keyof typeof formulaKeyMappings] || {};
  const hasMultipleCompanies =
    'hasMultipleCompanies' in formulaObject
      ? formulaObject.hasMultipleCompanies
      : false;
  const isSalesforce = formulaObject.category === formulaCategories.SALESFORCE;

  let formula = '{{';

  if (
    formulaType === Formulas.compareEmployeeCount ||
    formulaType === Formulas.compareRevenues
  ) {
    formula += 'COMPARE_TIMESERIES(NORMALIZE_TIMESERIES(';
    formula += `${
      formulaType === Formulas.compareEmployeeCount
        ? 'EMPLOYEE_COUNT'
        : 'REVENUES'
    }(COMPANY(${formatCompany(companies[0], isSalesforce)}))`;
    formula += '),NORMALIZE_TIMESERIES(';
    formula += `${
      formulaType === Formulas.compareEmployeeCount
        ? 'EMPLOYEE_COUNT'
        : 'REVENUES'
    }(COMPANY(${formatCompany(companies[1], isSalesforce)}))))`;
  } else if (
    formulaType === Formulas.sfdcLatestOpportunities &&
    extra?.sfdcQueryType
  ) {
    formula += `SFDC_LATEST_OPPORTUNITIES(`;
    const encodedQueryType = extra.sfdcQueryType
      .replace(/ /g, '_20')
      .replace(/>/g, '_3E')
      .replace(/\$/g, '_24');
    formula += `${encodedQueryType}(COMPANY(${formatCompany(
      companies[0],
      isSalesforce,
    )})))`;
  } else {
    // Find the correct formula key using formulaToKeyMappings
    const formulaKey = Object.entries(formulaToKeyMappings).find(
      ([_, value]) => value === formulaType,
    )?.[0];

    if (!formulaKey) {
      throw new Error(`Unknown formula type: ${formulaType}`);
    }

    formula += `${formulaKey}(`;

    if (hasMultipleCompanies) {
      formula += companies
        .map((company) => `COMPANY(${formatCompany(company, isSalesforce)})`)
        .join(',');
    } else {
      formula += `COMPANY(${formatCompany(companies[0], isSalesforce)})`;
    }
    formula += ')';
  }

  formula += '}}';

  return formula;
}

type CompanyIdentifier = 'currentRival' | 'currentCompany' | number | string;

function formatCompany(
  company: CompanyIdentifier,
  isSalesforce: boolean,
): string {
  if (company === undefined) {
    return '"currentRival"';
  }
  if (typeof company === 'number') {
    return isSalesforce ? '"currentRival"' : `"${company}"`;
  }

  if (typeof company === 'string') {
    if (company === 'currentRival' || company === 'currentCompany') {
      return `"${company}"`;
    }
    return `"${company}"`;
  }

  throw new Error(`Invalid company identifier: ${company}`);
}
