/* eslint-disable @typescript-eslint/consistent-type-definitions */
import { KlueReactNodeViewRenderer } from '@kluein/content-editor';
import { Node, mergeAttributes } from '@tiptap/core';

import DynamicBlockView from './components/DynamicBlockView';
import RenderViewDynamicBlock from './components/RenderViewDynamicBlock';

export interface DynamicBlockOptions {
  HTMLAttributes: Record<string, any>;
  cardId: number | null;
  profileId: number | null;
  contextMenuItems: string[];
  renderOnly?: boolean;
  companies?: Array<'currentRival' | 'currentCompany' | number | string>;
  currentRivalId?: number;
}

export type DynamicBlockAttributes = {
  companies: string[];
  cardId?: number | null;
  formulaKey: string | null;
  formulaType?: string | null;
  type?: string | null;
  sfdcQueryType?: string | null;
  profileId?: number | null;
  originalFormula?: string | null;
  blockValid?: boolean;
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    dynamicBlock: {
      insertDynamicBlock: (attrs: DynamicBlockAttributes) => ReturnType;
      removeDynamicBlock: (node: Node) => ReturnType;
    };
  }
}

export const DynamicBlock = Node.create<DynamicBlockOptions>({
  name: 'dynamicBlock',
  group: 'block',
  atom: true,
  draggable: true,
  contextMenuItems: ['delete'],
  useNodeViewRenderer: true,
  renderViewComponent: RenderViewDynamicBlock,
  addOptions() {
    return {
      ...((this.parent && this.parent()) || []),
      contextMenuItems: ['delete'],
      cardId: null,
      profileId: null,
      currentRivalId: undefined,
      renderOnly: false,
    };
  },

  addAttributes() {
    return {
      formulaKey: {
        default: null,
        parseHTML: (element) =>
          element.getAttribute('data-formula-key') || null,
        renderHTML: (attributes) => {
          return { 'data-formula-key': attributes.formulaKey || '' };
        },
      },
      companies: {
        default: [],
        parseHTML: (element) => {
          const companiesAttr = element.getAttribute('data-companies');
          if (companiesAttr) {
            try {
              return JSON.parse(companiesAttr);
            } catch {
              return [];
            }
          }
          return [];
        },
        renderHTML: (attributes) => {
          if (attributes.companies.length > 0) {
            return { 'data-companies': JSON.stringify(attributes.companies) };
          }
        },
      },
      cardId: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-card-id'),
        renderHTML: (attributes) => {
          if (attributes.cardId) {
            return { 'data-card-id': attributes.cardId };
          }
        },
      },
      formulaType: {
        default: null,
        parseHTML: (element) =>
          element.getAttribute('data-formula-type') || null,
        renderHTML: (attributes) => {
          if (attributes.formulaType) {
            return { 'data-formula-type': attributes.formulaType };
          }
        },
      },
      type: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-type'),
        renderHTML: (attributes) => {
          if (attributes.type) {
            return { 'data-type': attributes.type };
          }
        },
      },
      sfdcQueryType: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-sfdc-query-type'),
        renderHTML: (attributes) => {
          if (attributes.sfdcQueryType) {
            return { 'data-sfdc-query-type': attributes.sfdcQueryType };
          }
        },
      },
      profileId: {
        default: this.options.profileId,
        parseHTML: (element) => element.getAttribute('data-profile-id'),
        renderHTML: (attributes) => {
          if (attributes.profileId) {
            return { 'data-profile-id': attributes.profileId };
          }
        },
      },
      originalFormula: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-original-formula'),
        renderHTML: (attributes) => {
          if (attributes.originalFormula) {
            return { 'data-original-formula': attributes.originalFormula };
          }
        },
      },
      blockValid: {
        default: false,
        parseHTML: (element) => {
          return element.getAttribute('data-block-valid');
        },
        renderHTML: (attributes) => {
          return { 'data-block-valid': attributes.blockValid.toString() };
        },
        getStorage: (storage: DynamicBlockAttributes) => {
          const formulaKey = storage.formulaKey;
          const companies = storage.companies;
          return (
            !!formulaKey && Array.isArray(companies) && companies.length > 0
          );
        },
      },
      renderOnly: {
        default: false,
        parseHTML: (element) =>
          element.getAttribute('data-render-only') === 'true',
        renderHTML: (attributes) => {
          return { 'data-render-only': attributes.renderOnly.toString() };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div[data-block-type="dynamicBlock"]',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const attributes = mergeAttributes(this.options.HTMLAttributes, {
      'data-block-type': 'dynamicBlock',
      'data-render-only': HTMLAttributes['data-render-only'],
      'data-formula-key': HTMLAttributes['data-formula-key'],
      'data-companies': HTMLAttributes['data-companies'],
      'data-card-id': HTMLAttributes['data-card-id'],
      'data-formula-type': HTMLAttributes['data-formula-type'],
      'data-type': HTMLAttributes['data-type'],
      'data-sfdc-query-type': HTMLAttributes['data-sfdc-query-type'],
      'data-profile-id': HTMLAttributes['data-profile-id'],
      'data-original-formula': HTMLAttributes['data-original-formula'],
      'data-block-valid': HTMLAttributes['data-block-valid'],
    });

    return ['div', attributes];
  },

  addNodeView() {
    return KlueReactNodeViewRenderer(DynamicBlockView);
  },

  addCommands() {
    return {
      insertDynamicBlock:
        (attrs: DynamicBlockAttributes) =>
        ({ chain }) => {
          return chain()
            .focus()
            .insertContent({
              type: this.name,
              attrs: {
                ...attrs,
                cardId: this.options.cardId,
                companies: attrs.companies || [],
                extra: {
                  profileId: this.options.profileId ?? null,
                },
              },
            })
            .run();
        },
      removeDynamicBlock:
        (node: Node) =>
        ({ tr, state, dispatch }) => {
          const pos = state.doc.resolve(state.selection.from).before();
          const nodeAtPos = state.doc.nodeAt(pos);

          if (!!dispatch && nodeAtPos) {
            tr.delete(pos, pos + nodeAtPos.nodeSize);
            dispatch(tr);
            return true;
          }
          return false;
        },
    };
  },
});
