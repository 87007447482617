/* eslint-disable max-lines */
import intersection from 'lodash/intersection';

import type { CompanyLinkDefaultType } from './auth.types';
import type { UserType, CompanyType } from 'api/api.types';

type CheckAccessCompanyType = CompanyType | null;
type CheckAccessUserType = UserType | null;

const ADMIN_ROLES = ['admin'];
const CURATOR_ROLES = ['curator', 'admin'];

export function checkAdminAccess(
  user: UserType | null,
  visibilityGroup: number | null = null,
) {
  return (
    visibilityGroup === null && !!intersection(user?.roles, ADMIN_ROLES).length
  );
}

export function checkImpersonatingOrStaffAccess(user: UserType | null) {
  return !!user?.isStaff || !!user?.canImpersonate || !!user?.isImpersonating;
}

export function checkAdminOrStaff(user: UserType | null) {
  return !!user?.isStaff || checkAdminAccess(user);
}

export function checkCuratorAccess(
  user: UserType | null,
  visibilityGroup: number | null = null,
) {
  return (
    visibilityGroup === null &&
    !!intersection(user?.roles, CURATOR_ROLES).length
  );
}

export function checkReportsAccess(
  company: CheckAccessCompanyType,
  user: CheckAccessUserType,
  visibilityGroup: number | null = null,
) {
  if (!company || !user) return false;

  const isAdmin = checkAdminAccess(user, visibilityGroup);
  const isCurator = checkCuratorAccess(user, visibilityGroup);
  const curatorCanSeeReports =
    isCurator &&
    (checkIsCRAReportEnabledForCurators(company) ||
      checkIsConsumerReportEnabledForCurators(company) ||
      checkIsCuratorReportEnabledForCurators(company));

  return Boolean(
    (isAdmin || curatorCanSeeReports) &&
      Object.values(company?.usageReports ?? {}).length,
  );
}

export function checkTagVisibility(
  company: CheckAccessCompanyType,
  user: CheckAccessUserType,
  visibilityGroup: number | null,
) {
  if (!company || !user) return false;

  const isCurator = checkCuratorAccess(user, visibilityGroup);
  const isConsumersAllowed = !!company.companyData.cardTagIsConsumerVisible;

  return isCurator || isConsumersAllowed;
}

export function checkCardInfoVisibility(company: CheckAccessCompanyType) {
  if (!company) return false;

  let showCardInfo = company?.companyData?.showCardInfo;
  if (typeof showCardInfo === 'undefined') showCardInfo = true;
  return showCardInfo;
}

export function checkImpersonating(user: CheckAccessUserType) {
  return Boolean(user?.isImpersonating);
}

export function checkCardViewerUpdateAccess(company: CheckAccessCompanyType) {
  return !company?.companyData?.isExpandCardsDisabled;
}

export function checkDashboardNewsAccess(company: CheckAccessCompanyType) {
  if (!company) return false;
  return !company.companyData.isDashboardNewsDisabled;
}

export function checkCardFeedbackDisabled(company: CheckAccessCompanyType) {
  return Boolean(company?.companyData?.isCardFeedbackDisabled);
}

export function checkCardSentimentAccess(company: CheckAccessCompanyType) {
  return !company?.companyData?.isCardSentimentDisabled;
}

export function checkCardSentimentAndFeedbackOnHoverDisabled(
  company: CheckAccessCompanyType,
) {
  return Boolean(
    company?.companyData?.isCardSentimentAndFeedbackOnHoverDisabled,
  );
}

export function checkIsBattlecardLayoutLabelDisabled(
  company: CheckAccessCompanyType,
) {
  return Boolean(company?.companyData?.isBattlecardLayoutLabelDisabled);
}

export function checkIsAlertsAutoSummarizeOnTriageEnabled(
  company: CheckAccessCompanyType,
) {
  const isAlertsAutoSummarizeOnTriageEnabled =
    company?.companyData?.isAlertsAutoSummarizeOnTriageEnabled;
  if (typeof isAlertsAutoSummarizeOnTriageEnabled === 'undefined') return true;

  return Boolean(isAlertsAutoSummarizeOnTriageEnabled);
}

export function checkIsAlertsRecommendedTagEnabled(
  company: CheckAccessCompanyType,
) {
  const isAlertsRecommendedTagEnabled =
    company?.companyData?.isAlertsRecommendedTagEnabled;
  if (typeof isAlertsRecommendedTagEnabled === 'undefined') return true;
  return Boolean(isAlertsRecommendedTagEnabled);
}

export function checkIsWinLossEnabled(
  company: CheckAccessCompanyType,
  user: CheckAccessUserType,
  visibilityGroup: number | null = null,
) {
  const isCurator = checkCuratorAccess(user, visibilityGroup);

  // Explicitly check if companyData properties are undefined. If they are, use userData values.
  const winlossEnabled =
    company?.companyData?.isWinLossEnabled !== undefined
      ? company.companyData.isWinLossEnabled
      : user?.userData?.isWinLossEnabled;

  const consumerAccessEnabled =
    company?.companyData?.isWinLossConsumerAccessEnabled !== undefined
      ? company.companyData.isWinLossConsumerAccessEnabled
      : user?.userData?.isWinLossConsumerAccessEnabled;

  const userCanAccess = isCurator || consumerAccessEnabled;

  return Boolean(winlossEnabled && userCanAccess);
}

export function checkIsWinLossExclusive(
  company: CheckAccessCompanyType,
  user: CheckAccessUserType,
  visibilityGroup: number | null = null,
) {
  return (
    checkIsWinLossEnabled(company, user, visibilityGroup) &&
    Boolean(company?.companyData?.isWinLossExclusive)
  );
}

export function checkPATAccess(user: UserType | null) {
  return checkAdminAccess(user);
}

export function checkCompetitiveAnalyticsOverviewReportEnabled(
  user: UserType | null,
  company: CheckAccessCompanyType | null,
) {
  return Boolean(
    checkImpersonating(user) ||
      (checkAdminAccess(user) &&
        company?.companyData.isCompetitiveAnalyticsOverviewReportEnabled),
  );
}

export function checkCompetitiveAnalyticsThreatAnalysisReportEnabled(
  user: UserType | null,
  company: CheckAccessCompanyType | null,
) {
  const userHasAccess =
    (checkAdminAccess(user) || checkCuratorAccess(user)) &&
    checkIsCRAReportEnabledForCurators(company);
  return Boolean(
    checkImpersonating(user) ||
      (userHasAccess &&
        company?.companyData.isCompetitiveAnalyticsThreatAnalysisReportEnabled),
  );
}

export function checkCompetitiveAnalyticsRepPerformanceReportEnabled(
  user: UserType | null,
  company: CheckAccessCompanyType | null,
) {
  return Boolean(
    checkImpersonating(user) ||
      (checkAdminAccess(user) &&
        company?.companyData.isCompetitiveAnalyticsRepPerformanceReportEnabled),
  );
}

export function checkCompetitiveAnalyticsDataHygieneReportEnabled(
  user: UserType | null,
  company: CheckAccessCompanyType | null,
) {
  const userHasAccess =
    (checkAdminAccess(user) || checkCuratorAccess(user)) &&
    checkIsCRAReportEnabledForCurators(company);
  return Boolean(
    checkImpersonating(user) ||
      (userHasAccess &&
        company?.companyData.isCompetitiveAnalyticsDataHygieneReportEnabled),
  );
}

export function checkCompetitiveAnalyticsImpactReportEnabled(
  user: UserType | null,
  company: CheckAccessCompanyType | null,
) {
  return Boolean(
    checkImpersonating(user) ||
      (checkAdminAccess(user) &&
        company?.companyData.isCompetitiveAnalyticsImpactReportEnabled),
  );
}

export function checkIsCompetitiveAnalyticsProgramImpactReportEnabled(
  user: UserType | null,
  company: CheckAccessCompanyType | null,
) {
  return Boolean(
    checkImpersonating(user) ||
      (checkAdminAccess(user) &&
        company?.companyData.isCompetitiveAnalyticsProgramImpactReportEnabled),
  );
}

export function checkIsConsumerReportEnabledForCurators(
  company: CheckAccessCompanyType | null,
) {
  return Boolean(company?.companyData.isConsumerReportEnabledForCurators);
}

export function checkIsCuratorReportEnabledForCurators(
  company: CheckAccessCompanyType | null,
) {
  return Boolean(company?.companyData.isCuratorReportEnabledForCurators);
}

export function checkIsCRAReportEnabledForCurators(
  company: CheckAccessCompanyType | null,
) {
  return Boolean(company?.companyData.isCRAReportEnabledForCurators);
}

export function checkisCompetitiveDashboardEnabledForConsumers(
  company: CheckAccessCompanyType | null,
) {
  return Boolean(
    company?.companyData.isCompetitiveDashboardEnabledForConsumers,
  );
}

export function checkIsRivalLinkHiddenForConsumers(
  company: CheckAccessCompanyType | null,
  user: UserType | null,
  visibilityGroup: number | null = null,
) {
  /* Enabled for consumers only */
  const userHasAccess = !checkCuratorAccess(user, visibilityGroup);
  return (
    Boolean(company?.companyData.isRivalLinkHiddenForConsumers) && userHasAccess
  );
}

export function checkIsUnreadStateEnabledForConsumers(
  company: CheckAccessCompanyType,
  user: CheckAccessUserType,
  visibilityGroup: number | null = null,
) {
  /* Enabled for consumers only */
  const userHasAccess = !checkCuratorAccess(user, visibilityGroup);
  return (
    Boolean(company?.companyData?.isUnreadStateEnabledForConsumers) &&
    userHasAccess
  );
}

export function checkAskKlueEnabled(
  company: CompanyType | null,
  user: UserType | null,
) {
  return Boolean(
    company?.companyData?.isDashboardAskKlueEnabled ||
      (company?.companyData?.isDashboardAskKluePreviewForCuratorsEnabled &&
        checkCuratorAccess(user)),
  );
}

export function checkIsDashboardAskKlueEnabled(company: CompanyType | null) {
  return Boolean(company?.companyData.isDashboardAskKlueEnabled);
}

export function checkIsDashboardAskKluePreviewForCuratorsEnabled(
  company: CompanyType | null,
  user: UserType | null,
) {
  return Boolean(
    company?.companyData?.isDashboardAskKluePreviewForCuratorsEnabled &&
      checkCuratorAccess(user),
  );
}

export function checkDashboardNewsToggleDefault(
  company: CheckAccessCompanyType,
) {
  return Boolean(company?.companyData?.isDashboardNewsToggleDefaultOff);
}

export function checkSearchResultsAskKlueEnabled(
  company: CheckAccessCompanyType,
) {
  return Boolean(company?.companyData?.isSearchResultsAskKlueEnabled);
}

export function checkCompanyLandingPageEnabled(
  company: CheckAccessCompanyType,
) {
  return Boolean(company?.companyData?.isCompanyLandingPageEnabled);
}

export function checkCompanyFavoritesEnabled(
  company: CheckAccessCompanyType,
  user: CheckAccessUserType,
  visibilityGroup: number | null = null,
) {
  /* Enabled for consumers only */
  const userHasAccess = !checkCuratorAccess(user, visibilityGroup);
  return (
    Boolean(company?.companyData?.isCompanyFavoritesEnabled) && userHasAccess
  );
}

export function checkUpdateSummaryEnabled(company: CheckAccessCompanyType) {
  return Boolean(company?.companyData?.isUpdateSummaryEnabled);
}

export function checkCompanyLinkDefault(
  company: CheckAccessCompanyType,
  user: CheckAccessUserType,
  visibilityGroup: number | null = null,
): CompanyLinkDefaultType {
  const userHasAccess = !checkCuratorAccess(user, visibilityGroup);
  if (!userHasAccess) return 'profile';
  if (Boolean(company?.companyData?.isCompanyLinkDefaultLandingPage))
    return 'landing_page';
  if (Boolean(company?.companyData?.isCompanyLinkDefaultBattlecard))
    return 'battlecard';
  return 'profile';
}

export function checkGAEnabled(company: CheckAccessCompanyType) {
  return Boolean(company?.companyData?.isGAEnabled);
}

export function checkSFDCDataSyncContactsEnabled(
  company: CheckAccessCompanyType,
  user: CheckAccessUserType,
  visibilityGroup: number | null = null,
) {
  return checkIsWinLossEnabled(company, user, visibilityGroup);
}

export function checkIsReviewInsightsCreateCardEnabled(
  company: CheckAccessCompanyType,
) {
  const isReviewInsightsCreateCardEnabled =
    company?.companyData.isReviewInsightsCreateCardEnabled;
  if (typeof isReviewInsightsCreateCardEnabled === 'undefined') return true;
  return Boolean(isReviewInsightsCreateCardEnabled);
}

export function checkProfileSideNavNewsDisabled(
  company: CheckAccessCompanyType,
) {
  return Boolean(company?.companyData?.isProfileSideNavNewsDisabled);
}

export function checkIsGenAISearchResultsEnabled(
  company: CheckAccessCompanyType,
) {
  return Boolean(company?.companyData?.isGenAISearchResultsEnabled);
}

export function checkIsSearchSuggestionBattlecardsEnabled(
  company: CheckAccessCompanyType,
) {
  return Boolean(company?.companyData?.isSearchSuggestionBattlecardsEnabled);
}

export function checkResearchAccess(
  company: CheckAccessCompanyType,
  user: CheckAccessUserType,
  visibilityGroup: number | null = null,
) {
  const hasCuratorAccess =
    Boolean(company?.companyData?.researchEnabled) &&
    checkCuratorAccess(user, visibilityGroup);
  const hasStaffAccess =
    Boolean(company?.companyData?.researchEnabledForStaff) &&
    checkImpersonatingOrStaffAccess(user) &&
    checkCuratorAccess(user, visibilityGroup);

  return hasCuratorAccess || hasStaffAccess;
}

export function checkComposerAccess(
  company: CheckAccessCompanyType,
  user: CheckAccessUserType,
  visibilityGroup: number | null = null,
) {
  const hasCuratorAccess =
    Boolean(company?.companyData?.isComposerEnabled) &&
    checkCuratorAccess(user, visibilityGroup);
  const hasStaffAccess =
    Boolean(company?.companyData?.isComposerEnabledForStaff) &&
    checkImpersonatingOrStaffAccess(user) &&
    checkCuratorAccess(user, visibilityGroup);

  return hasCuratorAccess || hasStaffAccess;
}

export function checkResearchChatAccess(company: CheckAccessCompanyType) {
  return Boolean(company?.companyData.researchChatEnabled);
}

export function checkResearchDevAccess(company: CheckAccessCompanyType) {
  return Boolean(company?.companyData.researchDevEnabled);
}

export function checkAlertsTitleEditingDisabled(
  company: CheckAccessCompanyType,
) {
  return Boolean(company?.companyData?.alertTitleEditableDisabled);
}

export function checkIsSFDCDataSyncUserRoleEnabled(
  company: CheckAccessCompanyType,
) {
  return Boolean(company?.companyData?.isSFDCDataSyncUserRoleEnabled);
}

export function checkIsSFDSOnTemporalEnabled(company: CheckAccessCompanyType) {
  return Boolean(company?.companyData?.isSFDSOnTemporalEnabled);
}

export function checkisNewCuratorNavEnabled(company: CheckAccessCompanyType) {
  const isNewCuratorNavEnabled = company?.companyData.isNewCuratorNavEnabled;

  if (typeof isNewCuratorNavEnabled === 'undefined') return true;
  return Boolean(isNewCuratorNavEnabled);
}

export function checkIsFeedHiddenInConsumerNavEnabled(
  company: CheckAccessCompanyType,
) {
  return Boolean(company?.companyData?.isFeedHiddenInConsumerNavEnabled);
}

export function checkIsInsightsWhyWeWinLoseEnabled(
  company: CheckAccessCompanyType,
) {
  return Boolean(company?.companyData?.isInsightsWhyWeWinLoseEnabled);
}

export function checkIsCallInsightsDemoReplacementEnabled(
  company: CheckAccessCompanyType,
) {
  return Boolean(company?.companyData?.isCallInsightsDemoReplacementEnabled);
}

export function checkIfNewCardEditorEnabled(
  company: CheckAccessCompanyType,
  user: CheckAccessUserType,
  visibilityGroup: number | null = null,
) {
  const hasCuratorAccess = checkCuratorAccess(user, visibilityGroup);
  const hasStaffAccess =
    checkImpersonatingOrStaffAccess(user) &&
    checkCuratorAccess(user, visibilityGroup);

  return Boolean(
    (hasCuratorAccess || hasStaffAccess) &&
      company?.companyData?.enableNewCardEditor,
  );
}

export function checkIsNewCardRendererEnabled(company: CheckAccessCompanyType) {
  return Boolean(company?.companyData?.enableNewCardRenderer);
}

export function checkIsV2CuratorDashboardEnabled(
  company: CheckAccessCompanyType,
) {
  return !Boolean(company?.companyData?.isV2CuratorDashboardDisabled);
}

export function checkIsSFDSCustomLookupObjectEnabled(
  company: CheckAccessCompanyType,
) {
  return Boolean(company?.companyData?.isSFDSCustomLookupObjectEnabled);
}

export function checkIsHSDSIntegaritonEnabled(company: CheckAccessCompanyType) {
  return Boolean(company?.companyData?.isHSDSEnabled);
}

export function checkIsAskKlueWizardEnabled(company: CheckAccessCompanyType) {
  return Boolean(company?.companyData?.isAskKlueWizardEnabled);
}

export function checkIsAskKlueSingleClickPromptEnabled(
  company: CheckAccessCompanyType,
) {
  return Boolean(company?.companyData?.isAskKlueSingleClickPromptEnabled);
}

export function checkIsConsumerAskKlueToggleHidden(
  company: CheckAccessCompanyType,
) {
  return Boolean(company?.companyData?.isConsumerAskKlueToggleHidden);
}

export function checkKnowledgeHubEnabled(
  company: CheckAccessCompanyType,
  user: CheckAccessUserType,
  visibilityGroup: number | null,
) {
  const hasCuratorAccess = checkCuratorAccess(user, visibilityGroup);
  const hasStaffAccess =
    checkImpersonatingOrStaffAccess(user) &&
    checkCuratorAccess(user, visibilityGroup);

  return Boolean(
    (hasCuratorAccess || hasStaffAccess) &&
      company?.companyData?.isKnowledgeHubEnabled,
  );
}

export function checkIsBattlecardAskKlueEnabled(
  company: CheckAccessCompanyType,
) {
  return Boolean(company?.companyData?.isBattlecardAskKlueEnabled);
}
