import { createModel } from '@rematch/core';
import compact from 'lodash/compact';
import { batch } from 'react-redux';

import { fetchBattlecardCardsById } from 'api/endpoints/battlecards';
import {
  BattlecardType,
  type fetchBattlecardCardsByIdParamType,
} from 'api/endpoints/battlecards.types';
import { addVisibilityGroupIdToQuery, mergeMapValues } from 'store/utils';
import { parseCardsContent } from 'worker';

import type {
  BattlecardLayout,
  BattlecardsType,
  CardType,
  ProfileType,
  UserDataType,
} from 'api/api.types';
import type { RootModel } from 'store/models';
import type { StoreSelectors } from 'store/store.types';

export type StateType = {
  byId: Map<string, BattlecardsType>;
  allIds: Set<string>;
  currentBattlecardId?: number;
  currentBattlecardType?: BattlecardType;
  isLoadingCards: boolean;
};

export const initialState = {
  byId: new Map<string, BattlecardsType>(),
  allIds: new Set<string>(),
  currentBattlecardId: undefined,
  currentBattlecardType: undefined,
  isLoadingCards: true,
} as StateType;

type FetchBattlecardsPayload = {
  battlecardId: number;
  battlecardType: BattlecardType;
};

type PopulatePayload = { battlecards: Record<number, BattlecardsType> };

export const battlecards = createModel<RootModel>()({
  state: initialState,
  reducers: {
    populate: (state: StateType, { battlecards }: PopulatePayload) => ({
      ...state,
      byId: mergeMapValues(state.byId, battlecards),
      allIds: new Set([...state.allIds, ...Object.keys(battlecards)]),
    }),
    setCurrentBattlecardId: (state: StateType, id: number) => ({
      ...state,
      currentBattlecardId: id,
    }),
    resetCurrentBattlecard: (state: StateType) => ({
      ...state,
      currentBattlecardId: undefined,
      currentBattlecardType: undefined,
    }),
    setCurrentBattlecardType: (state: StateType, type: BattlecardType) => ({
      ...state,
      currentBattlecardType: type,
    }),
    setIsLoadingCards: (state: StateType, isLoadingCards: boolean) => ({
      ...state,
      isLoadingCards,
    }),
    update: (state: StateType, battlecard: Partial<BattlecardsType>) => {
      const { id } = battlecard;
      if (!id) {
        return { ...state };
      }
      return {
        ...state,
        byId: mergeMapValues(state.byId, { [id]: battlecard }),
      };
    },
    delete: (state: StateType, battlecardId: number) => {
      let currentBattlecardId = state.currentBattlecardId;
      let currentBattlecardType = state.currentBattlecardType;

      if (currentBattlecardId === battlecardId) {
        currentBattlecardId = undefined;
        currentBattlecardType = undefined;
      }

      return {
        ...state,
        byId: new Map(
          [...state.byId].filter(([key]) => parseInt(key, 10) !== battlecardId),
        ),
        allIds: new Set(
          [...state.allIds].filter((id) => parseInt(id, 10) !== battlecardId),
        ),
        currentBattlecardId,
        currentBattlecardType,
      };
    },
  },
  selectors: (slice, createSelector) => ({
    isLoading(models: StoreSelectors) {
      return createSelector(
        models.profiles.isLoading as any,
        (isLoading: boolean) => isLoading,
      );
    },
    byId() {
      return slice(({ byId }) => byId);
    },
    isLoadingCards() {
      return slice(({ isLoadingCards }) => isLoadingCards);
    },
    currentBattlecardIds(models: StoreSelectors) {
      return createSelector(
        models.profiles.currentProfile as any,
        (currentProfile: ProfileType | undefined) =>
          currentProfile?.battlecards,
      );
    },
    currentBattlecards() {
      return createSelector(
        this.byId as any, // @TODO Can we fix TypeScript?
        this.currentBattlecardIds as any,
        (
          byId: Map<string, BattlecardsType>,
          currentBattlecardIds: number[] | undefined,
        ) => {
          const battlecards = compact(
            currentBattlecardIds?.map((battlecardId) => {
              const battlecardItem = byId.get(battlecardId.toString());
              return battlecardItem;
            }),
          );

          return battlecards;
        },
      );
    },
    defaultBattlecardId() {
      return createSelector(
        this.currentBattlecardIds as any,
        (currentBattlecardIds: number[] | undefined) =>
          currentBattlecardIds?.find(() => true),
      );
    },
    currentBattlecardId() {
      return slice(({ currentBattlecardId }) => currentBattlecardId);
    },
    currentBattlecardType() {
      return slice(({ currentBattlecardType }) => currentBattlecardType);
    },
    currentBattlecard() {
      return createSelector(
        this.isLoading as any,
        this.byId as any,
        this.currentBattlecardId as any,
        (
          isLoading: boolean,
          byId: Map<string, BattlecardsType>,
          currentBattlecardId: number | undefined,
        ) => {
          if (!isLoading && currentBattlecardId) {
            return byId.get(currentBattlecardId.toString());
          }
        },
      );
    },
    currentBattlecardCards(models: StoreSelectors) {
      return createSelector(
        this.isLoadingCards as any,
        this.currentBattlecard as any,
        this.currentBattlecardType as any,
        models.cards.byId as any,
        (_: any, props: { isSingle: boolean }): boolean => props.isSingle,
        (
          isLoadingCards: boolean,
          currentBattlecard: BattlecardsType | undefined,
          type: BattlecardType | undefined,
          cardsById,
          isSingle,
        ) => {
          if (!isLoadingCards && currentBattlecard) {
            const cardIds =
              currentBattlecard?.cards[type ?? BattlecardType.desktop];
            const cards: Array<CardType | null> = [];
            cardIds.forEach((cardId) => {
              if (!cardId) {
                if (isSingle) {
                  return;
                }
                return;
              }
              const card = cardsById.get(cardId.toString());
              if (card) {
                cards.push(card);
              }
            });
            return cards;
          }
        },
      );
    },
    currentBattlecardLayout() {
      return createSelector(
        this.currentBattlecard as any,
        (currentBattlecard: BattlecardsType | undefined) =>
          currentBattlecard?.layout,
      );
    },
    userBattlecardLayouts(models: StoreSelectors) {
      return createSelector(
        models.auth.userData as any,
        (userData: UserDataType | undefined) => userData?.battlecardLayouts,
      );
    },
    currentUserBattlecardLayout() {
      return createSelector(
        this.currentBattlecardId as any,
        this.userBattlecardLayouts as any,
        (
          currentBattlecardId: number | undefined,
          userBattlecardLayouts:
            | { [key: string]: BattlecardLayout }
            | undefined,
        ) => {
          if (currentBattlecardId) {
            return userBattlecardLayouts?.[currentBattlecardId];
          }
        },
      );
    },
  }),
  effects: (dispatch) => {
    return {
      async fetchBattlecardCardsById(
        { battlecardId, battlecardType }: FetchBattlecardsPayload,
        rootState,
      ) {
        let shouldFetch = true;
        const queryObj: fetchBattlecardCardsByIdParamType = {
          v: '2',
          battlecardType,
        };
        addVisibilityGroupIdToQuery(rootState, queryObj);

        batch(() => {
          this.setCurrentBattlecardId(battlecardId);
          this.setCurrentBattlecardType(battlecardType);

          const battlecardCardIds = rootState.battlecards.byId.get(
            String(battlecardId),
          )?.cardTitles;

          if (battlecardCardIds && battlecardCardIds.length > 0) {
            const cardsInStore = rootState.cards.byId;
            // Find which cards are missing from the store
            const cardsToFetch = battlecardCardIds.filter(
              (cardId) => !cardsInStore.has(String(cardId)),
            );

            if (cardsToFetch.length === 0) {
              // If all the cards are already in the store, there's nothing to do.
              this.setIsLoadingCards(false);
              shouldFetch = false;
              return;
            } else {
              // Query any missing cards
              queryObj.cardIds = cardsToFetch;
            }
          }
          this.setIsLoadingCards(true);
        });

        if (!shouldFetch) {
          return;
        }

        const { data } = await fetchBattlecardCardsById({
          path: {
            id: battlecardId,
          },
          query: queryObj,
        });

        const parsedCards = parseCardsContent(data);

        batch(() => {
          dispatch.cards.populate({ cards: parsedCards });
          this.setIsLoadingCards(false);
        });
      },
      async updateUserLayout(layout: string, rootState) {
        const currentBattlecardId = rootState.battlecards.currentBattlecardId;
        const userData = rootState.auth.user?.userData;
        if (!currentBattlecardId || !userData) {
          throw new Error('State not ready');
        }

        await dispatch.auth.updateUserData({
          path: ['battlecardLayouts', `${currentBattlecardId}`],
          value: layout,
        });
      },
    };
  },
});
