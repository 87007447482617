import { useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  formulaCategories,
  formulaKeyMappings,
} from 'klue-html/formulas/constants';

import { reconstructFormula } from '../dynamic-block.utils';

import type { DynamicBlockAttributes } from '../dynamic-block';
import type { KlueNodeViewProps } from '@kluein/content-editor';
import type { RivalType } from 'api/api.types';
import type { Formulas } from 'klue-html/formulas';
import type { RootState } from 'store/store.types';

export const useDynamicBlock = (props: KlueNodeViewProps) => {
  const { node, updateAttributes, deleteNode } = props;
  const {
    companies,
    cardId,
    type,
    formulaKey,
    sfdcQueryType,
    profileId,
    originalFormula,
  } = node.attrs as DynamicBlockAttributes;
  const { t } = useTranslation();
  const rivalsMapByProfileId = useSelector<RootState, Map<string, string>>(
    (state) => state.rivals.byProfileId,
  );

  const [expanded, setExpanded] = useState(false);

  const formulaObject = useMemo(
    () =>
      formulaKeyMappings[formulaKey as keyof typeof formulaKeyMappings] || {},
    [formulaKey],
  );

  const isSalesforce = useMemo(
    () => formulaObject.category === formulaCategories.SALESFORCE,
    [formulaObject],
  );

  const hasMultipleCompanies = useMemo(
    () => (formulaObject as any)?.hasMultipleCompanies,
    [formulaObject],
  );

  const isBlockValid = useCallback(
    (attrs: Partial<DynamicBlockAttributes>) => {
      const newFormulaKey = attrs.formulaKey || formulaKey;
      const newFormula =
        formulaKeyMappings[newFormulaKey as keyof typeof formulaKeyMappings];
      const newCompanies = attrs.companies || companies;

      return (
        newFormula?.category === formulaCategories.SALESFORCE ||
        (newFormula?.hasMultipleCompanies
          ? newCompanies.length === 2 && newCompanies[0] !== newCompanies[1]
          : newCompanies.length >= 1)
      );
    },
    [formulaKey, companies],
  );

  const validateAndUpdateAttributes = useCallback(
    (attrs: Partial<DynamicBlockAttributes>) => {
      const newAttributes = {
        ...node.attrs,
        ...attrs,
        blockValid: isBlockValid(attrs),
      };

      updateAttributes(newAttributes);
      setExpanded(true);
    },
    [node.attrs, isBlockValid, updateAttributes],
  );

  const reconstructFormulaWithNewAttributes = useCallback(
    (newFormulaKey: string, newCompanies: string[]) => {
      return reconstructFormula({
        formulaType: newFormulaKey as Formulas,
        companies: newCompanies,
        extra: {
          profileId: profileId?.toString() ?? '',
          sfdcQueryType: sfdcQueryType ?? '',
        },
      });
    },
    [profileId, sfdcQueryType],
  );

  const handleSelectFormulaType = useCallback(
    (newFormulaKey: string) => {
      const formula =
        formulaKeyMappings[newFormulaKey as keyof typeof formulaKeyMappings];
      const newIsSalesforce =
        formula?.category === formulaCategories.SALESFORCE;

      const newCompanies = newIsSalesforce ? [] : companies;
      const newOriginalFormula = reconstructFormulaWithNewAttributes(
        newFormulaKey,
        newIsSalesforce ? ['currentRival'] : newCompanies,
      );

      validateAndUpdateAttributes({
        formulaKey: newFormulaKey,
        originalFormula: newOriginalFormula,
        companies: newCompanies,
      });
    },
    [
      companies,
      validateAndUpdateAttributes,
      reconstructFormulaWithNewAttributes,
    ],
  );

  const handleSelectRival = useCallback(
    (rival: RivalType, index: number) => {
      const updateCompanies = [...companies];
      updateCompanies[index] = rival.id.toString();

      if (!formulaKey) {
        return;
      }

      const newOriginalFormula = reconstructFormulaWithNewAttributes(
        formulaKey,
        updateCompanies,
      );

      validateAndUpdateAttributes({
        companies: updateCompanies,
        originalFormula: newOriginalFormula,
      });
    },
    [
      companies,
      formulaKey,
      validateAndUpdateAttributes,
      reconstructFormulaWithNewAttributes,
    ],
  );

  const getCurrentRival = useCallback(
    (keyValue: string) => {
      if (keyValue === 'currentRival' && profileId) {
        return rivalsMapByProfileId.get(profileId.toString()) ?? keyValue;
      }
      return keyValue;
    },
    [rivalsMapByProfileId, profileId],
  );

  const mappedCompanies = useMemo(
    () => companies.map(getCurrentRival),
    [companies, getCurrentRival],
  );

  const isConfigured = useMemo(
    () => (isSalesforce ? !!formulaKey : formulaKey && !!companies.length),
    [isSalesforce, formulaKey, companies],
  );

  const blockValid = useMemo(
    () =>
      node.attrs.blockValid !== false && (isSalesforce || companies.length > 0),
    [node.attrs.blockValid, isSalesforce, companies],
  );

  return {
    t,
    expanded,
    setExpanded,
    formulaKey,
    formulaObject,
    isSalesforce,
    hasMultipleCompanies,
    companies,
    mappedCompanies,
    isConfigured,
    blockValid,
    cardId,
    type,
    sfdcQueryType,
    profileId,
    originalFormula,
    handleSelectFormulaType,
    handleSelectRival,
    deleteNode,
  };
};
