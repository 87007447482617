import get from 'lodash/get';
import truncate from 'lodash/truncate';

import sanitize from 'klue-html/sanitizer';
import { APP_V1_BASEURL } from 'lib/urls';

import type { CardSourceType } from 'api/api.types';
import type { TFunction } from 'i18next';

type CardSourceUtilPropsType = {
  href: string;
  title: string;
};

type GetCardSourcesPropsType = {
  source: CardSourceType;
  t?: TFunction<'translation'>;
};

export const getCardSourcesProps = (
  props: GetCardSourcesPropsType,
): CardSourceUtilPropsType => {
  const { t = (() => '') as TFunction<'translation'>, source } = props;

  switch (source?.referableType) {
    case 'Link':
      return getLinkProps(source);

    case 'Comment':
      return getCommentProps({ source, t });

    default:
      return {
        href: '#',
        title: t('Card:sources.source'),
      };
  }
};

const getLinkProps = (source: CardSourceType) => {
  const { metadata, permalink } = source;
  const title =
    metadata?.referableMetadata?.title || truncate(permalink, { length: 150 });
  const href = permalink;

  return {
    title,
    href,
  };
};

const getCommentProps = (props: GetCardSourcesPropsType) => {
  const { source, t = () => '' } = props;
  const {
    metadata: { container },
    permalink,
  } = source;

  const { profileId, profileName, battlecardId, battlecardTitle, postTitle } =
    container || {};
  const commentType = !!profileId ? 'profile' : 'external';
  const commentArea = !!battlecardId ? 'battlecard' : 'board';
  const isCommentProfileRelated = commentType === 'profile';
  const commentMapByType = {
    profile: {
      battlecard: {
        title: `${profileName} "${battlecardTitle}" ${t(
          'Card:sources.battlecardComments',
        )}`,
        href: `${APP_V1_BASEURL}/profile/${profileId}/battlecard/${battlecardId}#comments`,
      },
      board: {
        title: `${profileName} ${t('Card:sources.boardComments')}`,
        href: `${APP_V1_BASEURL}/profile/${profileId}/view#comments`,
      },
    },
    external: {
      title: truncate(sanitize(postTitle || permalink), { length: 150 }),
      href: permalink,
    },
  };

  const title = get(
    commentMapByType[commentType],
    isCommentProfileRelated ? `${commentArea}.title` : 'title',
  ) as unknown as string;

  const href = get(
    commentMapByType[commentType],
    isCommentProfileRelated ? `${commentArea}.href` : 'href',
  ) as unknown as string;

  return {
    title,
    href,
  };
};

export const handleTableClicks = ({
  e,
  card,
  onOpenTable,
}: {
  e: MouseEvent;
  card?: HTMLElement;
  onOpenTable: ({
    header,
    table,
  }: {
    header: string | undefined;
    table: HTMLTableElement | null;
  }) => void;
}) => {
  if ((e.target as HTMLElement).classList.contains('zoom-image')) return;

  const table = (e.target as HTMLElement).closest('table');

  e.stopPropagation();
  e.preventDefault();

  const header = card?.querySelector('h1')?.textContent || undefined;

  if (table) {
    onOpenTable({ header, table });
  }
};

export const initTable = (tableWrapper: HTMLDivElement) => {
  const table = tableWrapper.querySelector('table');

  if (table) {
    table.classList.add('contentzoom-table');
  }
};

export const INPUT_PLACEHOLDER = 'https://example.com';
