import DynamicBlock from 'components/common/klueCard/blocks/dynamicBlock/DynamicBlock';

import type { KlueNodeViewProps } from '@kluein/content-editor';
import type { Formulas } from 'klue-html/formulas';

type DynamicBlockAttributes = KlueNodeViewProps['node']['attrs'];

const RenderViewDynamicBlock = (props: KlueNodeViewProps) => {
  const { formulaType, companies, cardId, formulaKey, profileId } = props.node
    .attrs as DynamicBlockAttributes;

  return (
    <DynamicBlock
      editorView
      formula={{
        formulaType: formulaType || (formulaKey as Formulas),
        companies,
        cardId: cardId ?? undefined,
        extra: {
          profileId: profileId ?? 0,
        },
      }}
    />
  );
};

export default RenderViewDynamicBlock;
