import { createModel } from '@rematch/core';
import isEmpty from 'lodash/isEmpty';

import {
  addCardSource,
  deleteCardSource,
  fetchCardSources,
} from 'api/endpoints/card';

import type { CardSourceType } from 'api/api.types';
import type { RootModel } from 'store/models';

export const initialState = {
  byId: new Map<string, Record<string, CardSourceType>>(),
  allIds: new Set<string>(),
  isLoading: false,
};

export type StateType = typeof initialState;

type PopulatePayload = { sources: Record<number, CardSourceType> };

export const cardsSources = createModel<RootModel>()({
  state: initialState,
  reducers: {
    populate: (state: StateType, { sources }: PopulatePayload) => {
      const newById = new Map(state.byId);
      const newAllIds = new Set(state.allIds);
      Object.keys(sources).forEach((key) => {
        const s = sources[key as unknown as number];
        if (s) {
          newById.set(key, sources[key as unknown as number] as any);
          newAllIds.add(key);
        } else {
          newById.delete(key);
          newAllIds.delete(key);
        }
      });
      return {
        byId: newById,
        allIds: newAllIds,
        isLoading: false,
      };
    },
    setIsLoading: (state: StateType, value: boolean) => {
      return {
        ...state,
        isLoading: value,
      };
    },
  },
  effects: (dispatch: any) => ({
    async loadOrFetchCardSources(id: number, rootState) {
      const existingSources = rootState.cardsSources.byId.get(id.toString());

      if (!isEmpty(existingSources)) {
        return;
      }

      dispatch.cardsSources.setIsLoading(true);

      const { data } = await fetchCardSources({
        path: {
          id,
        },
      });

      if (!data.length) {
        dispatch.cardsSources.setIsLoading(false);
        return;
      }

      dispatch.cardsSources.populate({ sources: { [id]: { ...data } } });
    },
    async addCardSource({ id, url }: { id: number; url: string }, rootState) {
      if (!url || !id) {
        return;
      }
      const existingSources = rootState.cardsSources.byId.get(id.toString());

      dispatch.cardsSources.setIsLoading(true);
      const { data } = await addCardSource({ path: { id }, params: { url } });

      dispatch.cardsSources.populate({
        sources: {
          [id]: {
            ...[...Object.values(existingSources || {}), data],
          },
        },
      });

      dispatch.cards.updateSourceCount({ cardId: id, increment: true });
      dispatch.cardsSources.setIsLoading(false);
    },
    async deleteCardSource(
      { cardId, id }: { cardId: number; id: number },
      rootState,
    ) {
      if (!cardId || !id) {
        return;
      }
      const existingSources = rootState.cardsSources.byId.get(
        cardId.toString(),
      );

      dispatch.cardsSources.setIsLoading(true);
      await deleteCardSource({ path: { id } });
      const updatedSources = Object.values(existingSources || {}).filter(
        (s) => s.id !== id,
      );
      dispatch.cardsSources.populate({
        sources: {
          [cardId]: updatedSources.length ? updatedSources : undefined,
        },
      });
      dispatch.cards.updateSourceCount({ cardId, increment: false });
      dispatch.cardsSources.setIsLoading(false);
    },
  }),
});
