import {
  List,
  Text,
  type ListItemType,
  Box,
  Button,
  Drop,
  PhosphorIcon,
  tokens,
} from '@kluein/klue-ui';
import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { formulaKeyMappings } from 'klue-html/formulas/constants';

type SelectFormulaTypeProps = {
  currentFormulaType: string;
  onSelect: (value: string) => void;
};

type FormulaWithKey =
  (typeof formulaKeyMappings)[keyof typeof formulaKeyMappings] & {
    key: string;
  };
type GroupedFormulas = Record<string, FormulaWithKey[]>;

const SelectFormulaType: React.FC<SelectFormulaTypeProps> = ({
  onSelect,
  currentFormulaType,
}) => {
  const { t } = useTranslation(['Card']);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const targetRef = useRef<HTMLDivElement | null>(null);

  const allFormulasObject = Object.entries(formulaKeyMappings);

  const groupedFormulas = allFormulasObject.reduce<GroupedFormulas>(
    (acc, [key, formula]) => {
      const category = formula.category;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push({ ...formula, key });
      return acc;
    },
    {},
  );

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const handleSelect = (value: string) => {
    onSelect(value);
    setIsDropdownOpen(false);
  };

  const renderItem = (item: ListItemType) => {
    if (item.subItems) {
      return (
        <Box pad="small">
          <Text fontWeight="semibold">{item.label}</Text>
          <List
            a11yTitle={`${item.label} options`}
            data={item.subItems}
            itemProps={{ size: 'small' }}
            renderItem={renderItem}
            selectedItem={currentFormulaType}
          />
        </Box>
      );
    }
    return (
      <Button
        disabled={item.label === currentFormulaType}
        onClick={() => handleSelect(item.key)}
        variant="flat-menu"
        title={t(
          'Card:editor.extensions.dynamicBlock.selectFormulaType.selectButtonTitle',
        )}
      >
        {item.label}
      </Button>
    );
  };

  const nestedData = Object.entries(groupedFormulas).map(
    ([category, formulas]) => ({
      key: category,
      label: category,
      subItems: formulas.map((formula) => ({
        key: formula.key,
        label: formula.label,
      })),
    }),
  );

  // Prevents keyboard interaction when dropdown is open
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (isDropdownOpen && e.key !== 'Escape') {
        e.preventDefault();
        e.stopPropagation();
      }
    };

    if (isDropdownOpen) {
      document.addEventListener('keydown', handleKeyDown, true);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [isDropdownOpen]);

  return (
    <Box ref={targetRef}>
      <Button
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        variant={currentFormulaType ? 'flat' : 'flat'}
        title={t(
          'Card:editor.extensions.dynamicBlock.selectFormulaType.selectButtonTitle',
        )}
        leftSlot={
          currentFormulaType ? (
            <PhosphorIcon.ChartBar size={tokens.iconSize.small} />
          ) : (
            <PhosphorIcon.PencilSimple size={tokens.iconSize.small} />
          )
        }
      >
        {currentFormulaType || 'Select Data Type'}
      </Button>
      {isDropdownOpen && targetRef.current && (
        <Drop
          align={{ top: 'bottom', left: 'left' }}
          target={targetRef.current}
          onClickOutside={closeDropdown}
          onEsc={closeDropdown}
          height={{ max: 'xlarge' }}
        >
          <List
            a11yTitle={t(
              'Card:editor.extensions.dynamicBlock.selectFormulaType.placeholder',
            )}
            truncateLength={15}
            itemGap="xsmall"
            pad="small"
            selectedItem={currentFormulaType}
            data={nestedData}
            renderItem={renderItem}
          />
        </Drop>
      )}
    </Box>
  );
};

export default SelectFormulaType;
