import {
  Box,
  TextInput,
  Anchor,
  Tag,
  Text,
  theme,
  tokens,
  PhosphorIcon,
} from '@kluein/klue-ui';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import useAuth from 'contexts/auth/useAuth';
import { useSearch } from 'contexts/ui/search-context';
import { useSearchParams } from 'hooks/search';
import { useDebounce } from 'hooks/use-debounce';
import { useRedirect } from 'hooks/use-redirect';
import {
  AnalyticsAction,
  AnalyticsCategory,
  useAnalytics,
} from 'lib/analytics';
import store from 'store';
import {
  Suggestion,
  type SuggestionType,
  type TagSuggestionType,
} from 'store/models/search-suggestions/searchSuggestions.model';
import TRACKING_IDS from 'tracking-ids';

import { SearchSuggestions } from './partials';

import type { Dispatch } from 'store/store.types';

export const LAYER_Z_INDEX = theme.layer?.zIndex || 10;

const SearchButton = styled(Box)`
  &:focus {
    box-shadow: none;
  }
  &:focus-visible {
    border: none;
    box-shadow: 0 0 2px 2px ${tokens.color.primary.main};
    outline: none;
  }

  svg {
    transform: scale(1.3);
  }
`;

type TagSuggestionProps = {
  suggestion: TagSuggestionType;
  onSuggestionSelect: (suggestion: SuggestionType) => void;
};

const TagSuggestion = ({
  suggestion,
  suggestion: {
    value: { name, synonym },
  },
  onSuggestionSelect,
}: TagSuggestionProps) => {
  const { t } = useTranslation();
  return (
    <Box direction="row" align="center" gap="xsmall">
      {!!synonym && (
        <Box direction="row" align="center" gap="xsmall">
          <Anchor
            a11yTitle={t('Common:navBar.search.searchFor', { item: name })}
            label={synonym}
          />
          <PhosphorIcon.CaretRight size={tokens.iconSize.xsmall} />
        </Box>
      )}
      <Tag
        variant="primary"
        label={name}
        onClick={() => onSuggestionSelect(suggestion)}
      />
    </Box>
  );
};

const NavbarGlobalSearch: React.FC = () => {
  const { t } = useTranslation(['Common', 'Search']);
  const { visibilityGroup, isSearchSuggestionBattlecardsEnabled } = useAuth();
  const { terms } = useSearchParams();
  const navigate = useNavigate();
  const { redirectToCompany } = useRedirect();
  const { search, context: searchContext } = useSearch();
  const [inputValue, setInputValue] = useState<string | undefined>(terms || '');
  const [suggestionOpen, setSuggestionOpen] = useState<boolean>(false);
  const {
    searchSuggestions: { getSuggestions, reset: resetSuggestions },
  } = useDispatch<Dispatch>();
  const { logEvent } = useAnalytics();
  const suggestions = useSelector(store.select.searchSuggestions.suggestions);
  const debouncedInputValue = useDebounce(inputValue, 250);
  const boxRef = useRef<HTMLDivElement>(null);

  const onSuggestionsOpen = () => !suggestionOpen && setSuggestionOpen(true);

  const onSuggestionsClose = useCallback((e?: any) => {
    if (e?.target === inputRef.current) return;
    setSuggestionOpen(false);
  }, []);

  const onSuggestionSelect = useCallback(
    (suggestion: SuggestionType) => {
      resetSuggestions();
      onSuggestionsClose();

      if (suggestion.type === Suggestion.TAG) {
        setInputValue('');
        logEvent({
          event: {
            category: AnalyticsCategory.search,
            action: AnalyticsAction.tagSearch,
            label: suggestion.value.name,
          },
        });
        search({
          tagId: suggestion.value.id,
          tagOp: 'add',
          reset: true,
        });
        return;
      }

      if (suggestion.type === Suggestion.BATTLECARD) {
        setInputValue('');
        logEvent({
          event: {
            category: AnalyticsCategory.search,
            action: AnalyticsAction.battlecardSearch,
            label: suggestion.value.url,
          },
        });
        navigate(suggestion.value.url);
        return;
      }

      setInputValue(suggestion.value);
      search({ terms: suggestion.value, reset: true });
    },
    [logEvent, onSuggestionsClose, navigate, resetSuggestions, search],
  );

  const suggestionComponent = useCallback(
    (item: SuggestionType): JSX.Element => {
      switch (item.type) {
        case Suggestion.TAG:
          return (
            <TagSuggestion
              suggestion={item}
              onSuggestionSelect={onSuggestionSelect}
            />
          );
        case Suggestion.BATTLECARD:
          return (
            <Box
              direction="row"
              gap="xsmall"
              align="center"
              fill="horizontal"
              style={{ display: 'inline-flex' }}
            >
              <Anchor
                onClick={() => {
                  redirectToCompany(item.value.rivalProfileId);
                  resetSuggestions();
                  onSuggestionsClose();
                  setInputValue('');
                }}
                title={`Go to the "${item.value.rivalName}" Board`}
                style={{ overflow: 'hidden' }}
                data-tracking-id={
                  TRACKING_IDS.global.appNavBar.globalSearch.suggestions
                    .battlecard.profile
                }
              >
                <Text truncate fontWeight="semibold">
                  {`${item.value.rivalName}`}
                </Text>
              </Anchor>
              <PhosphorIcon.CaretRight
                color={tokens.color.neutral[700]}
                size={tokens.iconSize.xsmall}
              />
              <PhosphorIcon.SquaresFour
                color={tokens.color.neutral[1000]}
                size={tokens.iconSize.xsmall}
              />
              <Anchor
                onClick={() => onSuggestionSelect(item)}
                title={`Go to the "${item.value.title}" Battlecard`}
                style={{ overflow: 'hidden' }}
                data-tracking-id={
                  TRACKING_IDS.global.appNavBar.globalSearch.suggestions
                    .battlecard.battlecardTitle
                }
              >
                <Text truncate fontWeight="semibold">
                  {item.value.title || t('Common:untitledBattlecard')}
                </Text>
              </Anchor>
            </Box>
          );
        case Suggestion.COMPLETION:
          return (
            <Anchor
              onClick={() => onSuggestionSelect(item)}
              a11yTitle={t('Common:navBar.search.searchFor', { item })}
              label={item.value}
            />
          );
      }
    },
    [
      onSuggestionSelect,
      t,
      redirectToCompany,
      resetSuggestions,
      onSuggestionsClose,
    ],
  );

  const BattleCardAndTagSuggestions = suggestions.filter(({ type }) =>
    [Suggestion.TAG, Suggestion.BATTLECARD].includes(type),
  );

  const suggestionItems = useMemo(() => {
    const filteredSuggestions =
      BattleCardAndTagSuggestions.length > 0
        ? [
            ...suggestions
              .filter(({ type }) => type === Suggestion.COMPLETION)
              .slice(0, 3),
            ...BattleCardAndTagSuggestions,
          ]
        : suggestions;

    return filteredSuggestions
      .filter(
        (item) =>
          !(
            !isSearchSuggestionBattlecardsEnabled &&
            item.type === Suggestion.BATTLECARD
          ),
      )
      .map((item, i) => ({
        label: (
          <Box
            border={
              i !== 0 && {
                color: tokens.color.neutral.lightgrey.main,
                position: 'between',
                side: 'top',
              }
            }
            direction="row"
            align="center"
            pad="medium"
            key={i}
            flex={{ shrink: 0 }}
          >
            {suggestionComponent(item)}
          </Box>
        ),
        value: item,
      }));
  }, [
    BattleCardAndTagSuggestions,
    isSearchSuggestionBattlecardsEnabled,
    suggestionComponent,
    suggestions,
  ]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: newValue } = event.target;
    setInputValue(newValue);

    !suggestionOpen && onSuggestionsOpen();
  };

  const onTextInputEnter = useCallback(() => {
    resetSuggestions();
    search({ terms: inputValue, reset: true });
  }, [inputValue, resetSuggestions, search]);

  const onTextInputKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const { key } = event;
      if (key === 'Enter') {
        onTextInputEnter();
        setSuggestionOpen(false);
      }
    },
    [onTextInputEnter],
  );

  const inputRef = useRef<HTMLInputElement>(null);
  const submitInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (searchContext === 'alerts') return;

    const searchTerms = debouncedInputValue.trim();
    if (!searchTerms || !debouncedInputValue) {
      resetSuggestions();
    } else {
      getSuggestions({ searchTerms, visibilityGroup });
    }
  }, [
    debouncedInputValue,
    getSuggestions,
    visibilityGroup,
    resetSuggestions,
    searchContext,
  ]);

  return (
    <>
      <Box
        background={tokens.color.neutral.white.main}
        direction="row"
        fill
        justify="center"
        alignSelf="center"
        ref={boxRef}
        round={{ size: '18px' }}
        width={{ max: 'xlarge' }}
        focusIndicator={false}
        hoverIndicator={{
          elevation: 'xsmall',
          background: {
            color: tokens.color.neutral.lightgrey.main,
          },
        }}
      >
        <TextInput
          ref={inputRef}
          aria-label={t('Common:navBar.search.label')}
          onChange={onChange}
          placeholder={t('Common:navBar.search.placeholder')}
          plain="full"
          reverse
          type="text"
          value={inputValue}
          onClick={onSuggestionsOpen}
          variant="global-search"
          onKeyDown={onTextInputKeyPress}
          data-tracking-id={TRACKING_IDS.global.appNavBar.globalSearch.input}
          style={{ zIndex: LAYER_Z_INDEX, position: 'relative' }}
        />
        <SearchButton
          ref={submitInputRef}
          align="center"
          background={tokens.color.neutral.black.main}
          direction="row"
          justify="center"
          onClick={onTextInputEnter}
          round={{ corner: 'right', size: '18px' }}
          title={t('Common:navBar.search.label')}
          width={{ min: 'xsmall' }}
          data-tracking-id={TRACKING_IDS.global.appNavBar.globalSearch.button}
          style={{ zIndex: LAYER_Z_INDEX, position: 'relative' }}
        >
          <PhosphorIcon.MagnifyingGlass
            color={tokens.color.neutral.white.main}
            size={tokens.iconSize.small}
          />
        </SearchButton>
      </Box>
      {suggestionOpen && (
        <SearchSuggestions
          inputRef={inputRef}
          onSuggestionsClose={onSuggestionsClose}
          suggestionItems={suggestionItems}
          submitInputRef={submitInputRef}
        />
      )}
    </>
  );
};

export default NavbarGlobalSearch;
