import {
  tokens,
  PhosphorIcon,
  Box,
  Heading,
  Text,
  Select,
  Button,
  type SelectItemProps,
  Image,
  getSizeFromTheme,
  Modal,
} from '@kluein/klue-ui';
import sortBy from 'lodash/sortBy';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { searchString } from 'lib/stringUtils';
import store from 'store';
import { useGetBoardsByRivalIdQuery } from 'store/api/boards';
import TRACKING_IDS from 'tracking-ids';

import type { BoardsType, RivalType } from 'api/api.types';

function normalizeSelectOptions(
  options: RivalType[] | BoardsType[],
  searchTerms?: string,
) {
  return options.reduce((acc, curr) => {
    const { id, name } = curr;
    if (!searchTerms || searchString(name, searchTerms)) {
      acc.push({
        value: id.toString(),
        label: name,
        icon: (curr as RivalType).iconUrl ? (
          <Image
            width={getSizeFromTheme('xxsmall')}
            height={getSizeFromTheme('xxsmall')}
            src={(curr as RivalType).iconUrl}
          />
        ) : (
          <PhosphorIcon.Columns size={tokens.iconSize.small} />
        ),
      });
    }
    return acc;
  }, [] as SelectItemProps[]);
}

function KlueCardMoveModal(props: {
  currentBoardId: number | undefined;
  handleClose: () => void;
  handleSave: (opts: { boardId: number }) => void;
}) {
  const { t } = useTranslation();
  const { currentBoardId, handleClose, handleSave } = props;
  const { rivals, currentRivalId } = useSelector(
    store.select(({ rivals: { all }, profiles: { currentRivalId } }) => ({
      rivals: all,
      currentRivalId,
    })),
  );
  const [rivalValue, setRivalValue] = useState<string>();
  const [search, setSearch] = useState<string>();
  const rivalOptions = useMemo(
    () => [...sortBy(normalizeSelectOptions(rivals, search), ['label'])],
    [rivals, search],
  );
  const [boardOptions, setBoardOptions] = useState<SelectItemProps[]>([]);
  const [boardValue, setBoardValue] = useState<string>();
  const loadSelectedRival = useCallback(() => {
    currentRivalId
      ? setRivalValue(currentRivalId.toString())
      : setRivalValue('');
  }, [currentRivalId]);

  const onClose = () => {
    setSearch(undefined);
    loadSelectedRival();
    handleClose();
  };

  const onSave = () => {
    handleSave({ boardId: parseInt(boardValue ?? '0', 10) });
    handleClose();
  };

  const boards = useGetBoardsByRivalIdQuery({
    path: { rivalId: parseInt(rivalValue ?? '0', 10) },
  });

  const isLoading = boardOptions.length === 0;

  useEffect(() => {
    loadSelectedRival();
  }, [loadSelectedRival]);

  useEffect(() => {
    if (boards.status === 'fulfilled' && boards.data) {
      const boardOptions = normalizeSelectOptions(
        boards.data as unknown as BoardsType[],
      )
        .slice(1) // slice(1) to remove the Scratchpad board
        .filter((board) => board.value !== currentBoardId?.toString());
      setBoardOptions(boardOptions);
      setBoardValue(boardOptions[0]?.value);
    }
  }, [currentBoardId, rivalValue, boards]);

  return (
    <Modal
      showCloseButton={false}
      width="xxxlarge"
      onClose={onClose}
      background={tokens.color.neutral.white.main}
      responsive
    >
      <Modal.Header background={tokens.color.primary.main}>
        <Box direction="row" gap="small" align="center">
          <PhosphorIcon.ArrowsLeftRight />
          <Heading
            size="medium"
            margin="none"
            level="2"
            color={tokens.color.primary.contrastmain}
          >
            {t('Card:header.menu.moveCard')}
          </Heading>
        </Box>
        <Button
          variant="plain"
          onClick={onClose}
          icon={<PhosphorIcon.X color={tokens.color.primary.contrastmain} />}
        />
      </Modal.Header>
      <Modal.Content>
        <Text
          size="medium"
          color={tokens.color.neutral.black.main}
          margin={{ vertical: 'small' }}
        >
          {t('Card:actions.moveCard.boardLabel')}
        </Text>
        <Select
          options={rivalOptions}
          onSelected={(item) => setRivalValue((item as SelectItemProps).value)}
          onSearch={setSearch}
          onClose={() => setSearch(undefined)}
          value={rivalValue}
          searchPlaceholder={t('Common:submitIntel.findCompetitor')}
          a11yTitle={t('Card:actions.moveCard.boardPlaceholder')}
          placeholder={t('Card:actions.moveCard.boardPlaceholder')}
          hasIcon={true}
          placeholderIcon={<PhosphorIcon.SquaresFour />}
          trackingIds={{
            select: TRACKING_IDS.global.card.moveCard.boardSelect,
            itemPrefix: TRACKING_IDS.global.card.moveCard.boardSelectItem,
          }}
        />
        <Text
          size="medium"
          color={tokens.color.neutral.black.main}
          margin={{ vertical: 'small' }}
        >
          {t('Card:actions.moveCard.laneLabel')}
        </Text>
        <Select
          onSelected={(item) => setBoardValue((item as SelectItemProps).value)}
          value={boardValue}
          forceUpdatePlaceholder={true}
          disabled={isLoading}
          options={boardOptions}
          hasIcon={true}
          placeholderIcon={<PhosphorIcon.Columns />}
          placeholder={
            isLoading
              ? t('Common:status.loading')
              : t('Card:actions.moveCard.lanePlaceholder')
          }
          trackingIds={{
            select: TRACKING_IDS.global.card.moveCard.laneSelect,
            itemPrefix: TRACKING_IDS.global.card.moveCard.laneSelectItem,
          }}
        />
        <Box
          direction="row"
          justify="end"
          margin={{ top: 'medium' }}
          gap="small"
        >
          <Button
            variant="flat-form"
            onClick={onClose}
            label={t('Common:actions.cancel')}
          />
          <Button
            variant="flat-outlined-form"
            onClick={onSave}
            label={t('Card:header.menu.moveCard')}
            disabled={
              !rivalValue ||
              !boardValue ||
              boardValue === currentBoardId?.toString()
            }
          />
        </Box>
      </Modal.Content>
    </Modal>
  );
}

export default KlueCardMoveModal;
