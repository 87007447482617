import { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import type { CardSourceType } from 'api/api.types';
import type { RootState, Dispatch } from 'store/store.types';

function useKlueCardSources({ cardId }: { cardId: number }) {
  const dispatch = useDispatch<Dispatch>();

  const cardSourcesList = useSelector<
    RootState,
    Record<string, CardSourceType> | undefined
  >((state) => {
    return state.cardsSources.byId.get((cardId || '').toString());
  }, shallowEqual);

  const isLoading = useSelector<RootState, boolean>((state) => {
    return state.cardsSources.isLoading;
  }, shallowEqual);

  useEffect(() => {
    cardId && dispatch.cardsSources.loadOrFetchCardSources(cardId);
  }, [dispatch, cardId]);

  const addSource = (url: string) => {
    return dispatch.cardsSources.addCardSource({ id: cardId, url });
  };
  const deleteSource = (id: number) => {
    return dispatch.cardsSources.deleteCardSource({
      cardId,
      id,
    });
  };

  return {
    cardSourcesList,
    isLoading,
    addSource,
    deleteSource,
  };
}

export default useKlueCardSources;
