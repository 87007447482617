import { api } from '../api';

import type {
  UpdateBattlecardsType,
  CreateBattlecardPayloadType,
} from 'api/api.types';

const injectedApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateBattlecard: build.mutation<any, UpdateBattlecardsType>({
      query: (battlecard) => {
        const { id, ...body } = battlecard;
        return {
          url: `/battlecards/${id}.json`,
          method: 'PUT',
          body,
          headers: {
            'Content-Type': 'application/json',
          },
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    createBattlecard: build.mutation<any, Partial<CreateBattlecardPayloadType>>(
      {
        query: (payload) => {
          const { profileId, ...body } = payload;

          return {
            url: `/profiles/${profileId}/battlecards.json`,
            method: 'POST',
            body,
            headers: {
              'Content-Type': 'application/json',
            },
          };
        },
        transformResponse: (response: any) => {
          return response;
        },
      },
    ),
    deleteBattlecard: build.mutation<void, number>({
      query: (battlecardId) => ({
        url: `/battlecards/${battlecardId}.json`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      transformResponse: (response: any) => {
        return response;
      },
    }),
  }),
});

export const enhancedApi = injectedApi.enhanceEndpoints({
  addTagTypes: ['Battlecards'],
});

export const {
  useUpdateBattlecardMutation,
  useCreateBattlecardMutation,
  useDeleteBattlecardMutation,
} = enhancedApi;
