import { createModel } from '@rematch/core';

import { fetchProfileRival } from 'api/endpoints/profile';
import { fetchRivals as fetchRivalsFromApi } from 'api/endpoints/rivals';
import { normalizeObject } from 'api/interceptors/normalize';
import { addVisibilityGroupIdToQuery, mergeMapValues } from 'store/utils';

import type { RivalType } from 'api/api.types';
import type {
  DashboardSortType,
  FetchRivalsQueryType,
  FetchRivalsReturnType,
  RivalWithSupportedRivalType,
} from 'api/endpoints/rivals.types';
import type { RootModel } from 'store/models';
import type { Dispatch, RootState } from 'store/store.types';

export type StateType = {
  byId: Map<string, RivalType>;
  byProfileId: Map<string, string>;
  allIds: Set<string>;
  isLoading: boolean;
  isFetching: boolean;
};

export const initialState = {
  byId: new Map(),
  byProfileId: new Map(),
  allIds: new Set(),
  isLoading: true,
  isFetching: false,
} as StateType;

type PopulatePayload = { rivals: Record<number, RivalType> };

export const mapRivalsByProfileId = (rivals: Record<number, RivalType>) =>
  Object.values(rivals).reduce((acc: Map<string, string>, cur: RivalType) => {
    acc.set((cur.profileId || cur.profile).toString(), cur.id.toString());

    return acc;
  }, new Map());

export const rivals = createModel<RootModel>()({
  state: initialState,
  reducers: {
    reset: (_state) => initialState,
    populate: (state, { rivals }: PopulatePayload) => {
      const byProfileId = mapRivalsByProfileId(rivals);

      return {
        ...state,
        byId: mergeMapValues(state.byId, rivals),
        allIds: new Set([...state.allIds, ...Object.keys(rivals)]),
        byProfileId: new Map([...state.byProfileId, ...byProfileId]),
        isLoading: false,
        isFetching: false,
      };
    },
    setIsFetching: (state, value: boolean) => {
      return {
        ...state,
        isFetching: value,
      };
    },
    updateRivalById: (
      state,
      { id, changes }: { id: string; changes: Partial<RivalType> },
    ) => {
      if (!state.byId.has(id)) {
        return state;
      }

      const updatedRival = {
        ...state.byId.get(id),
        ...changes,
      } as RivalType;

      return {
        ...state,
        byId: new Map(state.byId).set(id, updatedRival),
      };
    },
    deleteRivalById: (state, { id }: { id: string }) => {
      if (!state.byId.has(id)) {
        return state;
      }

      const byId = new Map(state.byId);
      byId.delete(id);
      const byProfileId = new Map(state.byProfileId);
      byProfileId.delete(
        Array.from(byProfileId.keys()).find(
          (profileId) => byProfileId.get(profileId) === id,
        ) as string,
      );

      return {
        ...state,
        byId,
        byProfileId,
        allIds: new Set([...state.allIds].filter((rivalId) => rivalId !== id)),
      };
    },
  },
  selectors: (slice, createSelector) => ({
    byId() {
      return slice(({ byId }) => byId);
    },
    allIds() {
      return slice(({ allIds }) => allIds);
    },
    all() {
      return createSelector(this.byId as any, (byId: Map<string, RivalType>) =>
        Array.from(byId.values()),
      );
    },
    allWithSupportedRival() {
      return createSelector(
        this.all as any,
        (rivals: Array<RivalType>): Array<RivalWithSupportedRivalType> =>
          rivals
            .filter(
              (
                item: RivalType | RivalWithSupportedRivalType,
              ): item is RivalWithSupportedRivalType =>
                (item as RivalWithSupportedRivalType).supportedRivalId !==
                undefined,
            )
            .sort((a, b) => a.name.localeCompare(b.name)),
      );
    },
    isLoading() {
      return slice(({ isLoading }) => isLoading);
    },
  }),
  effects: (dispatch) => ({
    fetchAllRivals(_, rootState) {
      const { rivals } = rootState;
      const hasRivals = rivals.allIds?.size > 0;

      if (rivals.isFetching || hasRivals) {
        return;
      }

      fetchRivals(dispatch, rootState);
    },
    fetchRivals(
      payload: {
        rivalGroupId?: number;
        order?: DashboardSortType;
        page?: number;
        limit?: number;
      },
      rootState,
    ) {
      return fetchRivals(dispatch, rootState, payload);
    },
    async loadOrFetchRivalByProfileId(
      profileId: number,
      rootState,
    ): Promise<RivalType | null> {
      const { byProfileId, byId } = rootState.rivals as any; // TODO Fix TS

      if (byProfileId.has(profileId.toString())) {
        return byId.get(byProfileId.get(profileId.toString()));
      }

      try {
        const { data } = await fetchProfileRival({
          path: { id: profileId },
        });

        dispatch.rivals.populate({ rivals: data.entities.rivals });

        return data.entities.rivals[data.result];
      } catch (error) {
        return null;
      }
    },
  }),
});

async function fetchRivals(
  dispatch: Dispatch,
  rootState: RootState,
  {
    rivalGroupId,
    order,
    page = 1,
    limit = 1000,
  }: {
    rivalGroupId?: number;
    order?: DashboardSortType;
    page?: number;
    limit?: number;
  } = {},
): Promise<FetchRivalsReturnType> {
  const query: FetchRivalsQueryType = {
    page,
    limit,
    minimal: true,
    icon: true,
    cardsCount: true,
  };
  if (rivalGroupId !== undefined) {
    query.rivalGroupId = rivalGroupId;
  }
  if (order !== undefined) {
    query.order = order;
  }
  addVisibilityGroupIdToQuery(rootState, query);

  const result = await fetchRivalsFromApi({
    query,
  });
  const { data: rivals } = normalizeObject()({
    ...result,
    data: result.data.items,
  });

  dispatch.rivals.populate({ rivals });
  return result.data;
}
