import { createModel } from '@rematch/core';

import { fetchCardsByLaneId, fetchProfileById } from 'api/endpoints/profile';
import { addVisibilityGroupIdToQuery, mergeMapValues } from 'store/utils';
import * as profileUtils from 'store/utils/profile';
import { parseCardsContent } from 'worker';

import type { BoardsType } from 'api/api.types';
import type {
  FetchCardsByLaneIdQueryType,
  FetchProfileByIdQueryType,
} from 'api/endpoints/profile.types';
import type { RootModel } from 'store/models';
import type { StoreSelectors } from 'store/store.types';

export type StateType = {
  byId: Map<string, BoardsType>;
  allIds: Set<string>;
  expanded: Set<number>;
};

export const initialState: StateType = {
  byId: new Map(),
  allIds: new Set(),
  expanded: new Set(),
};

type PopulatePayload = { boards: Record<number, BoardsType> };
type DeletePayload = { boardIds: Array<number> };
type DeleteCardsPayload = { cardIds: Array<number> };
type SetShouldUpdatePayload = { shouldUpdate: boolean; boardId: number };
type ToggleExpandedPayload = { boardId: number };
type MoveCardPayload = {
  cardId: number;
  toBoardId: number;
};

export const boards = createModel<RootModel>()({
  state: initialState,
  reducers: {
    addCardToBoard: (
      state: StateType,
      { boardId, card, cards }: { boardId: number; card: any; cards: any },
    ) => {
      const board = state.byId.get(boardId.toString());
      if (!board) {
        return state;
      }

      const sortedCards = [card, ...(cards || [])].sort(
        (a: any, b: any) => parseFloat(a.viewOrder) - parseFloat(b.viewOrder),
      );

      const newBoard = {
        ...board,
        cards: sortedCards,
        cardsCount: sortedCards.length,
      };

      return {
        ...state,
        byId: new Map(state.byId).set(boardId.toString(), newBoard),
      };
    },
    populate: (state: StateType, { boards }: PopulatePayload) => {
      return {
        ...state,
        byId: mergeMapValues(state.byId, boards),
        allIds: new Set([...state.allIds, ...Object.keys(boards)]),
      };
    },
    delete: (state: StateType, { boardIds }: DeletePayload) => {
      return {
        ...state,
        byId: new Map(
          [...state.byId].filter(
            (entry) => !boardIds.includes(parseInt(entry[0], 10)),
          ),
        ),
        allIds: new Set(
          [...state.allIds].filter(
            (id) => !boardIds.includes(parseInt(id, 10)),
          ),
        ),
      };
    },
    deleteCards: (state: StateType, { cardIds }: DeleteCardsPayload) => {
      return {
        ...state,
        byId: new Map(
          [...state.byId].map(([key, value]) => {
            const cards = (value?.cards ?? []).filter(
              (card) => !cardIds.includes(card.id),
            );
            return [key, { ...value, cards, cardsCount: cards.length }];
          }),
        ),
      };
    },
    setShouldUpdate: (
      state: StateType,
      { shouldUpdate, boardId }: SetShouldUpdatePayload,
    ) => {
      const id = boardId.toString();

      const byId = new Map(state.byId).set(id, {
        ...(state.byId.get(id) as BoardsType),
        shouldUpdate: shouldUpdate,
      });

      return {
        ...state,
        byId,
      };
    },
    toggleBoardExpanded: (
      state: StateType,
      { boardId }: ToggleExpandedPayload,
    ) => {
      const updatedExpanded = new Set(state.expanded);
      if (updatedExpanded.has(boardId)) {
        updatedExpanded.delete(boardId);
      } else {
        updatedExpanded.add(boardId);
      }
      return {
        ...state,
        expanded: updatedExpanded,
      };
    },
    moveCardToBoard: (
      state: StateType,
      { cardId, toBoardId }: MoveCardPayload,
    ) => {
      const targetBoard = state.byId.get(toBoardId.toString());
      const newById = new Map(state.byId);

      const [sourceBoardId, sourceBoard] =
        Array.from(state.byId.entries()).find(([_, board]) =>
          board.cards?.find((card) => card.id === cardId),
        ) ?? [];

      if (!sourceBoard || !sourceBoardId) {
        throw new Error('Source board not found');
      }

      const movedCard = sourceBoard.cards?.find((card) => card.id === cardId);
      const newSourceCards =
        sourceBoard.cards?.filter((card) => card.id !== cardId) ?? [];

      // Update source board
      newById.set(sourceBoardId, {
        ...sourceBoard,
        cards: newSourceCards,
        cardsCount: newSourceCards.length,
      });

      // Target board may not exist in the state if the profile was not visited yet
      if (!!targetBoard) {
        const targetCards = [movedCard!, ...(targetBoard.cards ?? [])]; // give option to move to beginning OR end
        newById.set(toBoardId.toString(), {
          ...targetBoard,
          cards: targetCards,
          cardsCount: targetCards.length,
        });
      }

      return {
        ...state,
        byId: newById,
      };
    },
  },
  selectors: (slice, createSelector) => ({
    byId() {
      return slice(({ byId }) => byId);
    },
    allIds() {
      return slice(({ allIds }) => allIds);
    },
    allBoardsCardIds() {
      return createSelector(
        this.allIds as any,
        this.byId as any,
        (allIds: StateType['allIds'], byId: StateType['byId']) => {
          const cardIds: number[] = [];
          allIds.forEach((boardId: string) => {
            const board = byId.get(boardId);
            if (board) {
              board.cards?.forEach((cardId: { id: number }) => {
                cardIds.push(cardId?.id);
              });
            }
          });
          return cardIds;
        },
      );
    },
    currentBoards(models: StoreSelectors) {
      return createSelector(
        models.profiles.currentProfileBoardIds as any,
        this.byId as any,
        (currentBoardIds: number[] | undefined, byId: StateType['byId']) => {
          return currentBoardIds?.map(
            (boardId) => byId.get(boardId.toString()) as BoardsType,
          );
        },
      );
    },
  }),
  effects: (dispatch) => ({
    async fetchAllCardsByBoardLaneId(boardId: number, rootState) {
      const shouldUpdate =
        rootState.boards.byId.get(boardId.toString())?.shouldUpdate ?? true;

      if (!shouldUpdate) {
        return;
      }

      dispatch.boards.setShouldUpdate({
        shouldUpdate: false,
        boardId,
      });

      const query: FetchCardsByLaneIdQueryType = { v: '2' };
      addVisibilityGroupIdToQuery(rootState, query);

      const { data } = await fetchCardsByLaneId({
        path: {
          boardId,
        },
        query,
      });

      const parsedCards = parseCardsContent(data);

      dispatch.cards.populate({ cards: parsedCards });
    },
    async checkBoardUpdates(profileId: number, rootState) {
      const query: FetchProfileByIdQueryType = {};
      addVisibilityGroupIdToQuery(rootState, query);

      const { data } = await fetchProfileById({
        path: {
          id: profileId,
        },
        query,
      });

      //this fn perform a comparison between the current board updatedAt date
      //and the new date returned by the request fetchProfileById.
      //if the new date is different than the current one we update the cards value
      //only for the lane that had an update.
      const updateBoardDataIfNeeded = (board: BoardsType) => {
        const lastBoardUpdatedAt = new Date(
          rootState.boards.byId.get(board.id.toString())?.updatedAt ?? '',
        ).getTime();

        if (lastBoardUpdatedAt < new Date(board.updatedAt).getTime()) {
          dispatch.boards.setShouldUpdate({
            shouldUpdate: true,
            boardId: board.id,
          });

          dispatch.boards.fetchAllCardsByBoardLaneId(board.id);
        }
      };

      Object.values(data.entities.boards).forEach(updateBoardDataIfNeeded);
    },
    observeBoardsUpdates(profileId: number) {
      profileUtils.observeBoardsUpdates({ profileId, dispatch });
    },
    removeObserveBoardsUpdates() {
      profileUtils.removeObserveBoardsUpdates();
    },
  }),
});
