import {
  List,
  Text,
  type ListItemType,
  Button,
  Drop,
  Box,
  PhosphorIcon,
  tokens,
} from '@kluein/klue-ui';
import sortBy from 'lodash/sortBy';
import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import store from 'store';

import type { RivalType } from 'api/api.types';

type SelectRivalProps = {
  onSelect: (rival: RivalType) => void;
  rivalId: string;
  prefix: string;
  selectRivalLabel: string;
};

const SelectRival: React.FC<SelectRivalProps> = ({
  onSelect,
  rivalId,
  prefix,
  selectRivalLabel,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const targetRef = useRef<HTMLDivElement | null>(null);
  const rivalsById = useSelector(store.select.rivals.byId);

  const allRivalsObject = sortBy(
    [...rivalsById.keys()].map((id) => {
      const rival = rivalsById.get(id.toString());
      return {
        key: rival?.id.toString() || '',
        label: rival?.name || '',
        iconUrl: rival?.iconUrl || '',
      };
    }),
    'label',
  );

  const handleSelect = (
    e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
    value: string,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    const rival = rivalsById.get(value);
    if (rival) {
      onSelect(rival);
    }
    setIsDropdownOpen(false);
  };

  const renderItem = (item: ListItemType) => (
    <Button
      disabled={item.label === rivalsById.get(rivalId.toString())?.name}
      // @TODO There is an issue with onClick event not being triggered in some cases, it needs more investigation
      onMouseDown={(
        e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
      ) => handleSelect(e, item.key)}
      variant="flat-menu"
    >
      {item.label}
    </Button>
  );

  // Prevents keyboard interaction when dropdown is open
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (isDropdownOpen && e.key !== 'Escape') {
        e.preventDefault();
        e.stopPropagation();
      }
    };

    if (isDropdownOpen) {
      document.addEventListener('keydown', handleKeyDown, true);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [isDropdownOpen]);

  return (
    <Box ref={targetRef}>
      <Button
        size="small"
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        variant={'flat-outlined'}
        title={selectRivalLabel}
        leftSlot={
          !rivalId ? (
            <PhosphorIcon.Plus size={tokens.iconSize.small} weight="bold" />
          ) : (
            <Text>{prefix}</Text>
          )
        }
      >
        {rivalsById.get(rivalId.toString())?.name || selectRivalLabel}
      </Button>
      {isDropdownOpen && targetRef.current && (
        <Drop
          align={{ top: 'bottom', left: 'left' }}
          target={targetRef.current}
          onClickOutside={() => setIsDropdownOpen(false)}
          onEsc={() => setIsDropdownOpen(false)}
        >
          <List
            a11yTitle={selectRivalLabel}
            truncateLength={15}
            itemGap="xsmall"
            pad="small"
            selectedItem={rivalId}
            data={allRivalsObject}
            renderItem={renderItem}
          />
        </Drop>
      )}
    </Box>
  );
};

export default SelectRival;
